import React from 'react';
import { Link } from 'react-router-dom'
import './style.css';
import { connect } from '../../store'
import { getUserId } from '../../lib/Router'
import Menu from './menu';

const Sidebar = (props) => {
    let user = getUserId();
    return (
        <div className="bg-main border-main" id="sidebar-wrapper">
            <div className="list-group list-group-flush">
                {Menu.map((e, i) => {
                    if (e.permission.includes(user.rol)) return (
                        <Link key={i} to={e.path} onClick={() => props.setActiveMenu(i)} className={`list-group-item list-group-item-action bg-main ${props.store.active_menu == i ? 'active-menu' : ''}`} style={{ display: 'flex', alignItems: 'center' }}>
                            {e.icon}
                            <span style={{ marginLeft: 15, marginTop: 5 }}>{e.title}</span>
                        </Link>
                    )
                })}
            </div>
        </div>
    )
}

export default connect(Sidebar);
