import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { RoleBasedRouting } from '../lib/permission'
// VIEWS GENERAL
import Dashboard from '../views/MiPanel';
import Experts from '../views/expertos'
import Clients from '../views/clientes'
import Services from '../views/servicios'
import CatServices from '../views/categoriasServicio'
import GroupServices from '../views/groupServices'
import SugerenciasServices from '../views/sugerenciasServicios'
import Operaciones from '../views/Operaciones';
import Roles from '../views/roles'
import Config from '../views/config'
import Nomina from '../views/nomina'
import Seguimiento from '../views/seguimiento';

const Rutas = () => (
        <Switch>
                <RoleBasedRouting path="/" exact component={Dashboard} roles={['superadmin', 'admin', 'full']} redirect="/experts"></RoleBasedRouting>
                <RoleBasedRouting path="/operations" exact component={Operaciones} roles={['superadmin', 'admin', 'full']} redirect="/experts"></RoleBasedRouting>
                <RoleBasedRouting path="/flow" exact component={Seguimiento} roles={['superadmin', 'admin', 'full']} redirect="/experts"></RoleBasedRouting>
                <RoleBasedRouting path="/experts" exact component={Experts} roles={['superadmin', 'admin', 'full', 'basic']} redirect="/"></RoleBasedRouting>
                <RoleBasedRouting path="/clients" exact component={Clients} roles={['superadmin', 'admin', 'full', 'basic']} redirect="/"></RoleBasedRouting>
                <RoleBasedRouting path="/services" exact component={Services} roles={['superadmin', 'admin', 'full']} redirect="/"></RoleBasedRouting>
                <RoleBasedRouting path="/catservices" exact component={CatServices} roles={['superadmin', 'admin', 'full']} redirect="/"></RoleBasedRouting>
                <RoleBasedRouting path="/groups" exact component={GroupServices} roles={['superadmin', 'admin', 'full']} redirect="/"></RoleBasedRouting>
                <RoleBasedRouting path="/suggestion" exact component={SugerenciasServices} roles={['superadmin', 'admin', 'full']} redirect="/"></RoleBasedRouting>
                <RoleBasedRouting path="/roles" exact component={Roles} roles={['superadmin', 'full']}></RoleBasedRouting>
                <RoleBasedRouting path="/config" exact component={Config} roles={['superadmin', 'full']}></RoleBasedRouting>
                <RoleBasedRouting path="/nomina" exact component={Nomina} roles={['superadmin', 'full']}></RoleBasedRouting>
        </Switch>
);

export default Rutas;