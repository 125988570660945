import React, { useState, useEffect } from "react";
import Endpoint from '../../Endpoint'
// Componentes
import axios from 'axios'
import Main from "../../components/Main";
import { Card } from '../../components/card';
import { connect } from '../../store';
import { BtnDelete, BtnEdit, BtnSee, SecondaryBtn } from '../../components/buttons'
import Table from '../../components/table';
import { ModalSimple } from '../../components/modal'
import { Row, Col } from '../../components/grid'
import ColorPicker from '../../components/colorPicker'
import { parseDataSelect } from '../../utils'
import fileDownload from 'js-file-download'
import './style.css';


const GroupServices = (props) => {
    const [see, setSee] = useState(false);
    const [edit, setEdit] = useState(null);
    const [remove, setRemove] = useState(false);
    const [current, setCurrent] = useState(false);
    const [nuevo, setNuevo] = useState(false);

    const [columns] = useState([
        { key: 'boxcolor', text: 'Color', color: 'color' },
        { key: 'image', text: 'Icono', image: 'icon', size: 50, endpoint: Endpoint },
        { key: 'color', text: 'Color hex.' },
        { key: 'name', text: 'Nombre' },
        { key: 'desc', text: 'Descripción' },
        { key: '_id', text: 'Cod. Interno' },
        {
            key: 'controls', text: 'Acciones', data: null, elem: (cat) => {
                return <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
                    <BtnEdit onClick={() => { setCurrent(cat); setSee(true) }} />
                    <BtnDelete onClick={() => { setCurrent(cat); setRemove(true) }} />
                </div>
            }
        }
    ])

    useEffect(() => {
        axios.get('/api/groups/service')
            .then(res => {
                props.setGroups(parseDataSelect(res.data.data, '_id', 'name'));
            })
            .catch(err => console.log(err))
    }, [])

    const removeGroup = () => {

        axios.delete(`/api/groups/service/${current._id}`)
            .then(response => {
                props.notify({ type: "success", title: "Eliminar grupo", text: "Grupo eliminado" });
            })
            .catch(err => {
                props.notify({ type: "error", title: "Eliminar grupo", text: "No se pudo eliminar el grupo" })
            })
    }

    const update = () => {

        const f = new FormData();
        f.append('name', edit.name);
        f.append('desc', edit.desc);
        f.append('color', edit.color);
        if (edit.icon) f.append('icon', edit.icon);

        axios.put(`/api/groups/service/${current._id}`, f)
            .then(res => {
                setEdit(null);
                props.notify({ type: "success", title: "Actualizar experto", text: "Experto actualizado" });
            })
            .catch(err => {
                console.log(err);
                setEdit(null);
                props.notify({ type: "error", title: "Actualizar experto", text: "No se pudo actualizar al experto" })
            })
    }

    const exportsDataCSV = (dataTable) => {
        axios.post(`/api/exports/csv/groups`, { data: dataTable })
            .then(res => {
                fileDownload(res.data.data, res.data.fileName);
            })
            .catch(err => console.log(err))
    }

    const exportsDataXLS = (dataTable) => {
        axios.post(`/api/exports/xls/groups`, { data: dataTable })
            .then(res => {
                fileDownload(res.data.data, res.data.fileName + '.xls');
            })
            .catch(err => console.log(err))
    }

    return (
        <Main>
            <Row>
                <Col>
                    <Card title="Grupos de servicios" button={true} textButton="Nuevo grupo" onClick={() => setNuevo(true)}>
                        <Row>
                            <Col>
                                <Table pagination={true} columns={columns} data={props.store.groups} background="#FF8B03" paginationClass="table-buttons" onExportsCSV={data => exportsDataCSV(data)} keyID="_id" onExportsXLS={data => exportsDataXLS(data)} pdf={false} />
                            </Col>
                        </Row>
                    </Card>
                </Col>
            </Row>
            <ModalSimple padding={20} open={see} onClose={setSee} cancel={true} textCancel="Modificar" accept={true} textAccept="Aceptar" onCancel={update}>
                <Row>
                    <Col>
                        <h4 className="mb-4">Datos del grupo</h4>
                    </Col>
                    <Col>
                        <TableEdit current={current} onChange={(d) => setEdit(d)} />
                    </Col>
                </Row>
            </ModalSimple>

            <ModalSimple padding={20} open={remove} onClose={setRemove} cancel={true} textCancel="Cancelar" accept={true} textAccept="Aceptar" onClick={removeGroup}>
                <Row>
                    <Col>
                        <h5>¿Esta seguro de eliminar este grupo {current.name}?</h5>
                    </Col>
                </Row>
            </ModalSimple>

            <NewCatServ open={nuevo} onClosed={setNuevo} accept={true} />
        </Main>
    )
}

export default connect(GroupServices);


const TableEdit = (props) => {

    const [data, setData] = useState({})

    useEffect(() => {
        setData(props.current);
    }, [props.current])

    const setDataKey = (key, value) => {
        let current = Object.assign({}, data);
        current[key] = value;
        setData(current);

        if (props.onChange != undefined) props.onChange(current);
    }

    return (
        <table className="table table-vertical-center ">
            <tbody>
                <tr>
                    <td><strong>Nombre:</strong></td>
                    <td><input style={{ margin: 0, padding: 0 }} type="text" className="form-control" value={data && data.name} onChange={v => setDataKey('name', v.target.value)} /></td>
                </tr>
                <tr>
                    <td><strong>Descripción:</strong></td>
                    <td><input style={{ margin: 0, padding: 0 }} type="text" className="form-control" value={data && data.desc} onChange={v => setDataKey('desc', v.target.value)} /></td>
                </tr>
                <tr>
                    <td><strong>Color:</strong></td>
                    <td>
                        <ColorPicker color={data && data.color} onChange={v => setDataKey('color', v)} />
                    </td>
                </tr>
                <tr>
                    <td><strong>Icono:</strong></td>
                    <td>
                        <input id="upled" placeholder="Icono" style={{ margin: 0, padding: 0, display: 'none' }} type="file" className="form-control" onChange={v => setDataKey('icon', v.target.files[0])} />
                        <label style={{ margin: 0, display: 'block', padding: "3px 5px", background: "#000", width: 100, color: "#fff", borderRadius: 6, textAlign: "center" }} htmlFor="upled">Cargar icono</label>
                        {data && data.icon ? <span style={{ fontSize: 10, marginTop: 5 }}>{typeof data.icon === "string" ? data.icon : data.icon.name}</span> : null}
                    </td>
                </tr>
                <tr>
                    <td className="pv-15"><strong>Codigo interno:</strong></td>
                    <td className="pv-15">{data && data._id}</td>
                </tr>
            </tbody>
        </table>
    )
}



const NewCatServ = connect((props) => {
    const [name, setName] = useState(null)
    const [desc, setDesc] = useState(null)
    const [color, setColor] = useState("#FF7C03");
    const [icon, setIcon] = useState(null);
    const [err, setErr] = useState(false)
    const [errMsg, setErrMsg] = useState('')

    const newErr = (status, message = '') => {
        setErr(status);
        setErrMsg(message);
    }

    const close = () => {
        if (props.onClosed != undefined) props.onClosed(false);
    }

    const save = () => {
        if (name == null || name.length == 0 || name === '') {
            newErr(true, 'El campo nombre es requerido');
            return false;
        } else newErr(false);

        if (desc == null || desc.length == 0 || desc === '') {
            newErr(true, 'El campo descripción es requerido');
            return false;
        } else newErr(false);

        const f = new FormData();
        f.append('name', name);
        f.append('desc', desc);
        f.append('color', color);
        if (icon) f.append('icon', icon);

        axios.post('/api/groups/service', f)
            .then(res => {
                props.notify({ type: "success", title: "Crear grupo de servicio", text: "Grupo creado" });
                setName(null);
                setDesc(null);
                setColor("#FF7C03");
                setIcon(null);
                setErr(false);
                setErrMsg('');
                if (props.onClosed != undefined) props.onClosed(false);
            })
            .catch(err => {
                props.notify({ type: "error", title: "Crear grupo de servicio", text: "Error al crear el grupo" })
            })
    }

    return (
        <ModalSimple padding={20} autoClose={false} open={props.open} onClose={close} cancel={props.cancel} textCancel={props.textCancel} accept={props.accept} textAccept={props.textAccept} onClick={save}>
            <Row>
                <Col>
                    <h4 className="mb-4">Nuevo grupo</h4>
                    <table className="table table-vertical-center">
                        <tbody>
                            <tr>
                                <td><strong>Nombre:</strong></td>
                                <td><input placeholder="Nombre" style={{ margin: 0, padding: 0 }} type="text" className="form-control" value={name} onChange={v => setName(v.target.value)} /></td>
                            </tr>
                            <tr>
                                <td><strong>Descripción:</strong></td>
                                <td><input placeholder="Descripción" style={{ margin: 0, padding: 0 }} type="text" className="form-control" value={desc} onChange={v => setDesc(v.target.value)} /></td>
                            </tr>
                            <tr>
                                <td><strong>Color:</strong></td>
                                <td>
                                    <ColorPicker color={color} onChange={v => setColor(v)} />
                                </td>
                            </tr>
                            <tr>
                                <td><strong>Icono:</strong></td>
                                <td>
                                    <input id="upl" placeholder="Icono" style={{ margin: 0, padding: 0, display: 'none' }} type="file" className="form-control" onChange={v => setIcon(v.target.files[0])} />
                                    <label style={{ margin: 0, display: 'block', padding: "3px 5px", background: "#000", width: 100, color: "#fff", borderRadius: 6, textAlign: "center" }} htmlFor="upl">Cargar icono</label>
                                    {icon ? <span style={{ fontSize: 10, marginTop: 5 }}>{icon.name}</span> : null}
                                </td>
                            </tr>
                        </tbody>
                        <br />
                        <br />
                        <br />
                    </table>
                </Col>
                <Col className="text-center">
                    {err ? <p style={{ color: "#ff4444" }}>{errMsg}</p> : null}
                </Col>
            </Row>
        </ModalSimple>
    )
})

NewCatServ.defaultProps = {
    open: false,
    cancel: true,
    accept: false,
    textCancel: "Cancelar",
    textAccept: 'Aceptar'
}