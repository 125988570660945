import {
    PUSH_NOTIFIACTION,
    REMOVE_NOTIFIACTION,
    UPDATE_VERSION,
    TOGGLE_SIDEBAR,
    SET_HEAD,
    TOOLS,
    GET_PROJECTS,
    TOGGLE_SIDESHEETS,
    SET_VIEWPORT,
    SET_GOBACK,
    ACTIVE_MENU,
    SET_FORM_TYPE,
    USER_ID_ACTIVATE,
    CAT_SERVICES,
    GROUPS_SERVICES,
    GET_EXPERTS,
    GET_CLIENTS,
    MASTER_SERVICES
} from './variables'

const setVersion = (data) => ({ type: UPDATE_VERSION, payload: data })
const setSidebar = (data) => ({ type: TOGGLE_SIDEBAR, payload: data })
const setSidesheet = (data) => ({ type: TOGGLE_SIDESHEETS, payload: data })
const setHead = (data) => ({ type: SET_HEAD, payload: data })
const setTools = (data) => ({ type: TOOLS, payload: data })
const getProjects = (data) => ({ type: GET_PROJECTS, payload: data })
const setViewport = (data) => ({ type: SET_VIEWPORT, payload: data })
const setGoback = (data) => ({ type: SET_GOBACK, payload: data })

const setActiveMenu = (data) => ({ type: ACTIVE_MENU, payload: data })
const pushNotification = (data) => ({ type: PUSH_NOTIFIACTION, payload: data });
const removeNotification = (data) => ({ type: REMOVE_NOTIFIACTION, payload: data });
const setFormType = (data) => ({ type: SET_FORM_TYPE, payload: data });
const setUserIdActivate = (data) => ({ type: USER_ID_ACTIVATE, payload: data });
const setCategories = (data) => ({ type: CAT_SERVICES, payload: data });
const setGroups = (data) => ({ type: GROUPS_SERVICES, payload: data });
const setExperts = (data) => ({ type: GET_EXPERTS, payload: data });
const setClients = (data) => ({ type: GET_CLIENTS, payload: data });
const setMasterServices = (data) => ({ type: MASTER_SERVICES, payload: data })

export default {
    setVersion,
    setSidebar,
    setHead,
    setTools,
    getProjects,
    setSidesheet,
    setViewport,
    setGoback,
    setActiveMenu,
    pushNotification,
    removeNotification,
    setFormType,
    setUserIdActivate,
    setCategories,
    setGroups,
    setExperts,
    setClients,
    setMasterServices
}