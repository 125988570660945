import React, { useState, useEffect } from "react";
import axios from 'axios'
// Componentes
import Main from "../../components/Main";
import Header from '../../components/Header';
import { CardWithTitle } from '../../components/card';
import { connect } from '../../store';
import { Row, Col } from "../../components/grid";
import Table from '../../components/table';
import Endopoint from '../../Endpoint'
import { BtnDownload, SecondaryBtn, BtnDelete } from '../../components/buttons'
import { ModalSimple } from '../../components/modal'
import DatePicker, { utils } from 'react-modern-calendar-datepicker';
import { localeEs } from '../MiPanel/locale'
import 'react-modern-calendar-datepicker/lib/DatePicker.css';
import moment from "moment";

const Nominas = (props) => {

	useEffect(() => {
		getNominas();
	}, [])

	const { years: year, months: month, date: day } = moment().subtract({ day: 7 }).toObject();
	const [dateStart, setDateStart] = useState({ from: { year, month: month + 1, day }, to: utils().getToday() })
	const [data, setData] = useState([])
	const [open, setOpen] = useState(false)
	const [openDelete, setOpenDelete] = useState(false)
	const [idNomina, setIdNomina] = useState("");
	const [columns] = useState([
		{ key: 'order', text: 'Orden' },
		{ key: 'custom', text: 'Fecha', elem: data => new Date(data.date).toLocaleString('cl-CL') },
		{ key: 'cant_experts', text: 'Cantidad de cuentas' },
		{ key: 'custom', text: 'Cantidad de servicios', elem: data => data.data.reduce((sum, current) => sum + current.data.length, 0) },
		{ key: 'custom', text: 'Monto total', elem: data => new Intl.NumberFormat("es-CL", { style: "currency", currency: "CLP" }).format(data.amount) },
		{
			key: 'controls', 'minWidth': 150, text: 'Descarga', elem: (user) => <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
				<BtnDownload onClick={() => downloadFile(user.file)} />
				<BtnDelete onClick={() => {
					setOpenDelete(true);
					setIdNomina(user._id)
				}} />
			</div>
		}
	])

	const downloadFile = (file) => {
		axios.get(`${Endopoint}/${file}`, { responseType: 'blob' })
			.then((response) => {
				const url = window.URL.createObjectURL(new Blob([response.data]));
				const link = document.createElement('a');
				link.href = url;
				link.setAttribute('download', file);
				document.body.appendChild(link);
				link.click();
			})
			.catch(err => console.log(err))
	}

	const removeNomina = () => {
		axios.delete(`/api/payments/nomina/${idNomina}`)
			.then(response => {
				getNominas();
				props.notify({ type: "success", title: "Eliminar nomina", text: "Nomina eliminada" });
			})
			.catch(err => props.notify({ type: "error", title: "Eliminar nomina", text: "Error al eliminar la nomina" }))
	}

	const generateNominas = () => {
		axios.post('/api/payments/nomina', dateStart)
			.then(res => {
				if (res.data && res.data.clear) props.notify({ type: "info", title: "Generar nomina", text: "No hay nuevos servicios por pagar" });
				else getNominas();
			})
			.catch(err => console.log(err));
	}

	const getNominas = () => {
		axios.get('/api/payments/nomina')
			.then(res => {
				setData(res.data.data);
			})
			.catch(err => console.log(err));
	}

	return (
		<Main>
			<Row>
				<Col>
					<Header title="Nominas de pago" />
					<br />
					<br />
				</Col>
			</Row>
			<CardWithTitle>
				<Row>
					<Col md={9}>
						<DatePicker
							className="form-control"
							value={dateStart}
							onChange={setDateStart}
							inputPlaceholder="Selecciona un rango de fecha"
							shouldHighlightWeekends
							calendarClassName="responsive-calendar"
							maximumDate={utils().getToday()}
							locale={localeEs}
						/>
					</Col>
					<Col md={3}>
						<SecondaryBtn onClick={() => setOpen(true)}>Generar nueva nomina</SecondaryBtn>
					</Col>
				</Row>
			</CardWithTitle>

			<CardWithTitle>
				<Row>
					<Col>
						<Table columns={columns} data={data} background="#FF8B03" keyID="_id" exports={false} paginationClass="table-buttons" pagination={false} />
					</Col>
				</Row>
			</CardWithTitle>
			<ModalSimple padding={20} open={open} onClose={setOpen} cancel={true} textCancel="Cancelar" accept={true} textAccept="Aceptar" onClick={generateNominas}>
				<Row>
					<Col className="text-center">
						<h5>¿Deseas generar una nueva nomina?</h5>
					</Col>
				</Row>
			</ModalSimple>
			<ModalSimple padding={20} open={openDelete} onClose={setOpenDelete} cancel={true} textCancel="Cancelar" accept={true} textAccept="Aceptar" onClick={removeNomina}>
				<Row>
					<Col className="text-center">
						<h5>¿Deseas eliminar esta nomina?</h5>
					</Col>
				</Row>
			</ModalSimple>
		</Main>
	)
}

export default connect(Nominas);

