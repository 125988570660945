import React from 'react'


export const Container = props => {
    return <div className={`container ${props.className}`}>{props.children}</div>
}

Container.defaultProps = {
    className:""
}

export const Row = props => {
    return <div style={props.style} className={`row ${props.className}`}>{props.children}</div>
}

Row.defaultProps = {
    className:"",
    style:{}
}

export const Col = props => {
    return <div className={`${props.className} col-${props.xs} ${props.sm != undefined ? `col-sm-${props.sm}`:''} ${props.md != undefined ? `col-md-${props.md}`:''} ${props.xl != undefined ? `col-xl-${props.xl}`:''}`} style={props.style}>{props.children}</div>
}

Col.defaultProps = {
    className:"",
    xs:12,
    sm:undefined,
    md:undefined,
    xl:undefined,
    style:{}   
}