import React from 'react';
import './style.css';
import { connect } from '../../store'
import { getUserId } from '../../lib/Router'
import Avatar from '../avatar';
import { closeSession } from '../../lib/Router'
import { Link } from 'react-router-dom'
import { logoNavbar } from '../../logos'
const size = 15;
const Navbar = (props) => {

	const toggle = () => {
		props.setSidebar(!props.store.sidebar);
	}

	let user = getUserId();

	return (
		<nav className="navbar d-flex align-items-center">
			<div className="col-6 d-flex justify-content-start p-0 align-items-center">
				<button className="btn-menu" onClick={() => toggle()}>
					<svg width={size} height={size} viewBox="0 0 384 384" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M192 85.334C215.564 85.334 234.667 66.2313 234.667 42.667C234.667 19.1027 215.564 0 192 0C168.436 0 149.333 19.1027 149.333 42.667C149.333 66.2313 168.436 85.334 192 85.334Z" fill="black" />
						<path d="M192 234.667C215.564 234.667 234.667 215.564 234.667 192C234.667 168.436 215.564 149.333 192 149.333C168.436 149.333 149.333 168.436 149.333 192C149.333 215.564 168.436 234.667 192 234.667Z" fill="black" />
						<path d="M192 384C215.564 384 234.667 364.897 234.667 341.333C234.667 317.769 215.564 298.666 192 298.666C168.436 298.666 149.333 317.769 149.333 341.333C149.333 364.897 168.436 384 192 384Z" fill="black" />
					</svg>

				</button>
				{logoNavbar}
			</div>
			<div className="col-6 d-flex justify-content-end align-items-center">
				<div className="dropdown mobile-none">
					<button className="container-avatar" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
						<Avatar className="mleft" src={user.avatar} />
						<h6 style={{ marginLeft: 11, lineHeight: "16.41px" }} >{user.name}</h6>
					</button>
					<div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
						<Link to="/perfil" className="dropdown-item">Mi perfil</Link>
						<a className="dropdown-item cursor-pointer" onClick={() => closeSession()}>Cerrar sesión</a>
					</div>
				</div>
			</div>
		</nav>
	)
}


export default connect(Navbar);
