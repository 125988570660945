import React, { useEffect } from "react";

const Main = (props) => {
    return (
        <div className="container-fluid" id={props.id}>
            {props.children}
        </div>
    )
}

Main.defaultProps = {
    id: Math.random().toString(36).slice(-8)
}

export default Main;