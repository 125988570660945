import React, { useState, useEffect, Component, Fragment } from 'react';
import axios from 'axios';
import { setToken } from '../../lib/Router';
import './style.css';
import { PrimaryBtn, SecondaryBtn } from '../../components/buttons';
import { connect } from '../../store'
import Notifications from '../../components/notify';
import { useHistory } from "react-router-dom";
import { logoWhite } from '../../logos'




const Login = (props) => {
    const history = useHistory();
    const [mail, setMail] = useState("");
    const [username, setUserName] = useState("");
    const [pass, setPass] = useState("");
    const [err, setErr] = useState(false);
    const [vd, setVd] = useState(true)
    const [msg, setMsg] = useState("")
    const [typeAlert, setTypeAlert] = useState(0);


    const login = () => {
        setVd(false);
        setMsg("Iniciando sesion");
        setTypeAlert(2);
        return axios.post('/adm/login', JSON.stringify({ 'email': username, 'password': pass }))
            .then(res => {
                if (res.data.success) {
                    setErr(false)
                    setToken(res.data.token);
                    history.push('/');
                } else {
                    setVd(false);
                    setMsg("Usuario o password incorrectos");
                    setTypeAlert(0);
                }

            })
            .catch(error => {
                switch (error.status) {
                    case 401:
                        setErr(true);
                    default:
                        console.log(error);
                }
                return error;
            })
    }

    const recoveryPassword = () => {
        axios.put('/restorePassword', JSON.stringify({ 'correo': mail }))
            .then(res => console.log(res))
            .catch(err => console.log(err));
    }

    return (
        <Fragment>
            <div className="login_app">
                <div className="app_form">
                    <div className="form tt-login text-center">
                        <svg width="153" height="120" viewBox="0 0 153 120" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="76.9375" cy="43.9643" r="43.9643" fill="#FA5805" />
                            <path d="M97.1668 37.2576L94.1807 34.3015V25.2297C94.1807 24.1605 93.2969 23.2853 92.2164 23.2853H89.2063C88.1258 23.2853 87.242 24.1605 87.242 25.2297V27.4326L78.8069 19.0823C77.7783 18.0639 76.0974 18.0639 75.0688 19.0823L56.7092 37.2576C55.6781 38.2757 54.8384 40.2842 54.8384 41.7228V47.868V65.1622C54.8384 66.6001 56.0284 67.7783 57.4817 67.7783H96.3943C97.847 67.7783 99.0366 66.6001 99.0366 65.1622V58.4505V55.5071V41.7228C99.0366 40.2842 98.195 38.2757 97.1668 37.2576Z" fill="white" />
                            <path d="M76.5957 36.0449C69.3544 36.0449 63.463 41.9363 63.463 49.1777C63.463 58.2793 76.6086 73.8268 76.6086 73.8268C76.6086 73.8268 89.7285 57.8317 89.7285 49.1777C89.7285 41.9363 83.8373 36.0449 76.5957 36.0449ZM80.5582 53.023C79.4656 54.1154 78.0308 54.6617 76.5957 54.6617C75.1609 54.6617 73.7257 54.1154 72.6335 53.023C70.4486 50.8383 70.4486 47.2833 72.6335 45.0984C73.6915 44.0399 75.0989 43.4569 76.5957 43.4569C78.0926 43.4569 79.4997 44.0401 80.5582 45.0984C82.7431 47.2833 82.7431 50.8383 80.5582 53.023Z" fill="#FA5805" />
                            <path d="M81.6793 97.3667V101.649H84.6578V105.063H81.6793V113.757C81.6793 114.401 81.8027 114.862 82.0496 115.141C82.2965 115.42 82.7687 115.56 83.4664 115.56C83.9816 115.56 84.4378 115.522 84.8349 115.447V118.973C83.9226 119.252 82.9834 119.392 82.0174 119.392C78.7544 119.392 77.0907 117.744 77.0263 114.449V105.063H74.4825V101.649H77.0263V97.3667H81.6793Z" fill="black" />
                            <path d="M102.566 119.07C102.351 118.651 102.195 118.131 102.099 117.508C100.972 118.764 99.5065 119.392 97.7033 119.392C95.9967 119.392 94.5798 118.898 93.4528 117.911C92.3365 116.923 91.7784 115.678 91.7784 114.175C91.7784 112.329 92.46 110.912 93.8231 109.925C95.197 108.937 97.1773 108.438 99.7641 108.428H101.905V107.429C101.905 106.624 101.696 105.98 101.278 105.497C100.87 105.014 100.22 104.773 99.3294 104.773C98.5459 104.773 97.9287 104.961 97.4779 105.336C97.0378 105.712 96.8178 106.227 96.8178 106.882H92.1648C92.1648 105.873 92.4761 104.939 93.0986 104.08C93.7212 103.222 94.6013 102.551 95.7391 102.068C96.8768 101.574 98.1541 101.327 99.5709 101.327C101.718 101.327 103.419 101.869 104.675 102.953C105.941 104.027 106.574 105.54 106.574 107.494V115.045C106.585 116.698 106.816 117.948 107.267 118.796V119.07H102.566ZM98.7176 115.834C99.4045 115.834 100.038 115.683 100.617 115.383C101.197 115.072 101.626 114.658 101.905 114.143V111.148H100.167C97.8374 111.148 96.5977 111.953 96.4475 113.563L96.4314 113.837C96.4314 114.417 96.6353 114.894 97.0432 115.27C97.451 115.646 98.0092 115.834 98.7176 115.834Z" fill="black" />
                            <path d="M121.697 112.082L120.022 113.757V119.07H115.37V94.3398H120.022V108.041L120.924 106.882L125.384 101.649H130.971L124.675 108.911L131.518 119.07H126.173L121.697 112.082Z" fill="black" />
                            <path d="M148.299 119.07C148.084 118.651 147.928 118.131 147.832 117.508C146.705 118.764 145.24 119.392 143.436 119.392C141.73 119.392 140.313 118.898 139.186 117.911C138.07 116.923 137.512 115.678 137.512 114.175C137.512 112.329 138.193 110.912 139.556 109.925C140.93 108.937 142.911 108.438 145.497 108.428H147.639V107.429C147.639 106.624 147.429 105.98 147.011 105.497C146.603 105.014 145.953 104.773 145.063 104.773C144.279 104.773 143.662 104.961 143.211 105.336C142.771 105.712 142.551 106.227 142.551 106.882H137.898C137.898 105.873 138.209 104.939 138.832 104.08C139.454 103.222 140.334 102.551 141.472 102.068C142.61 101.574 143.887 101.327 145.304 101.327C147.451 101.327 149.152 101.869 150.408 102.953C151.674 104.027 152.308 105.54 152.308 107.494V115.045C152.318 116.698 152.549 117.948 153 118.796V119.07H148.299ZM144.451 115.834C145.138 115.834 145.771 115.683 146.351 115.383C146.93 115.072 147.36 114.658 147.639 114.143V111.148H145.9C143.571 111.148 142.331 111.953 142.181 113.563L142.165 113.837C142.165 114.417 142.368 114.894 142.776 115.27C143.184 115.646 143.742 115.834 144.451 115.834Z" fill="black" />
                            <path d="M7.19679 97.3667V101.649H10.1753V105.063H7.19679V113.757C7.19679 114.401 7.32023 114.862 7.5671 115.141C7.81397 115.42 8.28624 115.56 8.98392 115.56C9.49912 115.56 9.9553 115.522 10.3524 115.447V118.973C9.44009 119.252 8.50091 119.392 7.5349 119.392C4.27192 119.392 2.60823 117.744 2.54383 114.449V105.063H0V101.649H2.54383V97.3667H7.19679Z" fill="#FA5805" />
                            <path d="M22.8988 119.07H18.2297V101.649H22.8988V119.07ZM17.956 97.1413C17.956 96.4436 18.1868 95.8694 18.6483 95.4186C19.1206 94.9678 19.7593 94.7423 20.5643 94.7423C21.3585 94.7423 21.9918 94.9678 22.4641 95.4186C22.9364 95.8694 23.1725 96.4436 23.1725 97.1413C23.1725 97.8497 22.931 98.4293 22.448 98.8801C21.9757 99.3309 21.3478 99.5563 20.5643 99.5563C19.7807 99.5563 19.1474 99.3309 18.6644 98.8801C18.1922 98.4293 17.956 97.8497 17.956 97.1413Z" fill="#FA5805" />
                            <path d="M38.2949 112.082L36.6205 113.757V119.07H31.9675V94.3398H36.6205V108.041L37.5221 106.882L41.9818 101.649H47.5686L41.2734 108.911L48.116 119.07H42.7707L38.2949 112.082Z" fill="#FA5805" />
                            <path d="M59.7125 119.07H55.0434V101.649H59.7125V119.07ZM54.7697 97.1413C54.7697 96.4436 55.0005 95.8694 55.462 95.4186C55.9343 94.9678 56.5729 94.7423 57.3779 94.7423C58.1722 94.7423 58.8055 94.9678 59.2778 95.4186C59.75 95.8694 59.9862 96.4436 59.9862 97.1413C59.9862 97.8497 59.7447 98.4293 59.2617 98.8801C58.7894 99.3309 58.1615 99.5563 57.3779 99.5563C56.5944 99.5563 55.9611 99.3309 55.4781 98.8801C55.0058 98.4293 54.7697 97.8497 54.7697 97.1413Z" fill="#FA5805" />
                            <path d="M71.3303 108.079C71.6954 108.079 72.0129 108.214 72.2828 108.484C72.5527 108.754 72.6877 109.071 72.6877 109.436V110.294C72.6877 110.659 72.5527 110.976 72.2828 111.246C72.0129 111.516 71.6954 111.651 71.3303 111.651H65.472C65.1069 111.651 64.7894 111.516 64.5195 111.246C64.2496 110.976 64.1146 110.659 64.1146 110.294V109.436C64.1146 109.071 64.2496 108.754 64.5195 108.484C64.7894 108.214 65.1069 108.079 65.472 108.079H71.3303Z" fill="#FA5805" />
                        </svg>

                        <br />
                        <br />
                        {/* <h4>Inicio Sesión </h4> */}
                        {err ? <span className="error-label">Los datos ingresados no son correctos</span> : null}
                        <form className="mx-auto py-4">
                            <input type="text" className={err ? "form-control mb-10 error" : "form-control mb-10"} placeholder="Nombre de usuario" onChange={value => setUserName(value.target.value)}></input>

                            <input type="password" className={err ? 'form-control mb-20 error' : 'form-control mb-20'} placeholder="Contraseña" onChange={value => setPass(value.target.value)}></input>
                            <br />
                            <p className="recoverPassModalText" data-toggle="modal" data-target="#recoverPassModal">¿Olvidaste tu contraseña?</p>
                            <br />
                            <br />
                            <SecondaryBtn onClick={() => login()}>Entrar</SecondaryBtn>
                            <br />
                            <br />
                            {vd ? null : <div>
                                {typeAlert == 0 ?
                                    <div className="alert alert-danger" role="alert">{msg}</div> :
                                    typeAlert == 1 ? <div className="alert alert-success" role="alert">{msg}</div> :
                                        <div className="alert alert-info" role="alert">{msg}</div>}
                            </div>}
                        </form>
                    </div>
                </div>
            </div>
            {/* Modal */}
            <div className="modal fade" id="recoverPassModal" tabIndex={-1} role="dialog" aria-labelledby="recoverPassModalTitle" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body form mx-auto">
                            <p>Para recuperar tu contraseña, ingresa el correo electrónico asociado a tu cuenta de usuario:</p>
                            <div className="mx-auto">
                                <input type="email" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Ingresa tu email" onChange={val => setMail(val.target.value)} />
                            </div>
                        </div>
                        <div className="modal-footer mx-auto">
                            <button data-toggle="modal" data-target="#result" data-dismiss="modal" aria-label="Close" type="button" onClick={() => recoveryPassword()} className="btn-sl my-1">Enviar</button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade" id="result" tabIndex={-1} role="dialog" aria-labelledby="resultTitle" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body form mx-auto">
                            <h4>¡Mensaje enviado!</h4>
                            <p>Hemos enviado un mensaje a ejemplo@email.com para que puedas elegir tu nueva contraseña.</p>
                            <p>¿No has recibido el correo? <a data-toggle="modal" data-target="#recoverPassModal" data-dismiss="modal" aria-label="Close">Inténtalo de nuevo</a></p>
                        </div>
                        <div className="modal-footer mx-auto"></div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default connect(Login);