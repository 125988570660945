import React, { useState, useEffect } from "react";
// Componentes
import axios from 'axios'
import Main from "../../components/Main";
import { Card } from '../../components/card';
import Table from '../../components/table';
import { connect } from '../../store';
import { BtnDelete, BtnEdit, BtnSee } from '../../components/buttons'
import { ModalSimple } from '../../components/modal'
import './style.css';
import { Row, Col } from '../../components/grid'
import Toggle from '../../components/toggle'
import fileDownload from 'js-file-download'
import { UnlockAccess, grantPermission } from '../../lib/permission'


const Experts = (props) => {
  const [data, setData] = useState([])
  const [edit, setEdit] = useState(null);
  const [see, setSee] = useState(false);
  const [remove, setRemove] = useState(false);
  const [service, setService] = useState(false);
  const [current, setCurrent] = useState({});

  const [columns] = useState([
    { key: 'rut', text: 'Rut', maxWidth: 90 },
    { key: 'name', text: 'Nombre' },
    { key: 'lastname', text: 'Apellido' },
    { key: 'email', text: 'Email' },
    { key: 'phone', text: 'Teléfono', maxWidth: 200 },
    { key: 'steps.str', text: 'Pasos completados' },
    {
      key: 'controls', 'minWidth': 175, text: 'Acciones', data: null, elem: (u) => {
        return <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
          <BtnSee onClick={() => { setCurrent(u); setSee(true); }} />
          <BtnEdit onClick={() => { setCurrent(u); setService(true) }} />

          <UnlockAccess request={['superadmin', 'admin', 'full']}>
            <BtnDelete onClick={() => { setCurrent(u); setRemove(true) }} />
          </UnlockAccess>
        </div>
      }
    }
  ])

  useEffect(() => {
    axios.get('/api/experts')
      .then(res => props.setExperts(res.data.data))
      .catch(err => console.log(err))
  }, [])

  const exportsDataCSV = (dataTable) => {
    axios.post(`/api/exports/csv/experts`, { data: dataTable })
      .then(res => {
        fileDownload(res.data.data, res.data.fileName);
      })
      .catch(err => console.log(err))
  }

  const exportsDataXLS = (dataTable) => {
    axios.post(`/api/exports/xls/experts`, { data: dataTable })
      .then(res => {
        fileDownload(res.data.data, res.data.fileName + '.xls');
      })
      .catch(err => console.log(err))
  }

  const removeUser = () => {

    axios.delete(`/api/user/${current._id}`)
      .then(response => {
        props.notify({ type: "success", title: "Eliminar experto", text: "Experto eliminado" });
      })
      .catch(err => {
        props.notify({ type: "error", title: "Eliminar experto", text: "No se pudo eliminar al experto" })
      })
  }

  const update = () => {
    axios.put(`/api/user/update/${current._id}`, {
      name: edit.name,
      lastname: edit.lastname,
      email: edit.email,
      phone: edit.phone,
      valid: edit.valid
    })
      .then(res => {
        props.notify({ type: "success", title: "Actualizar experto", text: "Experto actualizado" });
      })
      .catch(err => {
        console.log(err);
        props.notify({ type: "error", title: "Actualizar experto", text: "No se pudo actualizar al experto" })
      })
  }

  return (
    <Main>

      <Row>
        <Col>
          <Card title="Expertos registrados">
            <Table columns={columns} data={props.store.experts} background="#FF8B03" paginationClass="table-buttons" onExportsCSV={data => exportsDataCSV(data)} keyID="_id" onExportsXLS={data => exportsDataXLS(data)} pdf={false} pagination={false} />
          </Card>
        </Col>

        <ModalSimple width="800px" padding={20} open={see} onClose={setSee} cancel={grantPermission(['superadmin', 'admin', 'full'])} textCancel="Modificar" accept={true} textAccept="Aceptar" onCancel={update}>
          <Row>
            <Col>
              <h4 className="mb-4">Datos del experto</h4>
            </Col>
            <Col>
              <TableEdit current={current} onChange={(d) => setEdit(d)} />
            </Col>
          </Row>
        </ModalSimple>

        <ModalSimple padding={20} open={remove} onClose={setRemove} cancel={true} textCancel="Cancelar" accept={true} textAccept="Aceptar" onClick={removeUser}>
          <Row>
            <Col>
              <h5>¿Esta seguro de eliminar al experto {current.name} {current.lastname}?</h5>
            </Col>
          </Row>
        </ModalSimple>

        <ModalSimple width="800px" padding={20} open={service} onClose={setService} cancel={false} textCancel="Cancelar" accept={true} textAccept="Aceptar">
          <Row>
            <Col>
              <TableService current={current} />
            </Col>
          </Row>
        </ModalSimple>

      </Row>

    </Main>
  )
}

export default connect(Experts);


const TableEdit = (props) => {

  const [data, setData] = useState({})

  useEffect(() => {
    setData(props.current);
  }, [props.current])

  const setDataKey = (key, value) => {
    let current = Object.assign({}, data);
    current[key] = value;
    setData(current);

    if (props.onChange != undefined) props.onChange(current);
  }

  return (
    <table className="table table-vertical-center ">
      <tbody>
        <tr>
          <td><strong>Rut:</strong></td>
          <td><input disabled={!grantPermission(['superadmin', 'admin', 'full'])} style={{ margin: 0, padding: 0 }} type="text" className="form-control" value={data && data.rut} onChange={v => setDataKey('rut', v.target.value)} /></td>
        </tr>
        <tr>
          <td><strong>Nombre:</strong></td>
          <td><input disabled={!grantPermission(['superadmin', 'admin', 'full'])} style={{ margin: 0, padding: 0 }} type="text" className="form-control" value={data && data.name} onChange={v => setDataKey('name', v.target.value)} /></td>
        </tr>
        <tr>
          <td><strong>Apellido:</strong></td>
          <td><input disabled={!grantPermission(['superadmin', 'admin', 'full'])} style={{ margin: 0, padding: 0 }} type="text" className="form-control" value={data && data.lastname} onChange={v => setDataKey('lastname', v.target.value)} /></td>
        </tr>
        <tr>
          <td><strong>Email:</strong></td>
          <td><input disabled={!grantPermission(['superadmin', 'admin', 'full'])} style={{ margin: 0, padding: 0 }} type="text" className="form-control" value={data && data.email} onChange={v => setDataKey('email', v.target.value)} /></td>
        </tr>
        <tr>
          <td><strong>Teléfono:</strong></td>
          <td><input disabled={!grantPermission(['superadmin', 'admin', 'full'])} style={{ margin: 0, padding: 0 }} type="text" className="form-control" value={data && data.phone} onChange={v => setDataKey('phone', v.target.value)} /></td>
        </tr>
        <tr>
          <td className="pv-15"><strong>Dirección:</strong></td>
          <td className="pv-15">{data && data.address} {data && data.address_num}</td>
        </tr>
        <tr>
          <td className="pv-15"><strong>Depto / otro:</strong></td>
          <td className="pv-15">{data && data.address_other}</td>
        </tr>
        <tr>
          <td><strong>Habilitado:</strong></td>
          <td><Toggle disabled={!grantPermission(['superadmin', 'admin', 'full'])} value={data && data.valid} onChange={v => setDataKey('valid', v)} /></td>
        </tr>
        {data && data.steps && data.steps.missing && data.steps.missing.length > 0 ? <tr>
          <td><strong>Pasos por completar:</strong></td>
          <td><ul>{data.steps.missing.map(e => <li>{e}</li>)}</ul></td>
        </tr> : null}
      </tbody>
    </table>
  )
}


const TableService = (props) => {

  const [data, setData] = useState({})

  useEffect(() => {
    setData(props.current);
  }, [props.current])

  const setDataKey = (key, value) => {
    let current = Object.assign({}, data);
    current[key] = value;
    setData(current);
  }

  if (props.current.servicios.length == 0) return <h5 className="text-center">Sin servicios para mostrar</h5>

  return (
    <table className="table table-vertical-center ">
      <thead>
        <th>Nombre</th>
        <th>Categoria</th>
        <th>Min.</th>
        <th>Max.</th>
      </thead>
      <tbody>
        {
          props.current.servicios.map(e => {
            return <tr>
              <td>{e.name}</td>
              <td>{e.categoria.name}</td>
              <td>{e.min}</td>
              <td>{e.max}</td>
            </tr>
          })
        }
      </tbody>
    </table>
  )
}
