import React, { useState, useEffect } from "react";
// Componentes
import axios from 'axios'
import Main from "../../components/Main";
import Header from '../../components/Header';
import { Card } from '../../components/card';
import { connect } from '../../store';
import { Bar, HorizontalBar } from 'react-chartjs-2';
import DatePicker, { utils } from 'react-modern-calendar-datepicker';
import { localeEs } from './locale'
import 'react-modern-calendar-datepicker/lib/DatePicker.css';
import moment from "moment";
import './style.css';

const Dashboard = (props) => {

  useEffect(() => {
    getSales();
    getTopTenCategory();
    getTopTenServices();
    getTopTenExpert();
    getTopTenClient();
    getTopTenCity();
    getTopTenRegion()
  }, []);
  
  const { years: year, months: month, date: day } = moment().subtract({ day: 30 }).toObject();
  const [data, setData] = useState({ labels: [], datasets: [] })
  const [dataCat, setDataCat] = useState({ labels: [], datasets: [] })
  const [dataServ, setDataServ] = useState({ labels: [], datasets: [] })
  const [dataExp, setDataExp] = useState({ labels: [], datasets: [] })
  const [dataClient, setDataClient] = useState({ labels: [], datasets: [] })
  const [dataCity, setDataCity] = useState({ labels: [], datasets: [] })
  const [dataRegion, setDataRegion] = useState({ labels: [], datasets: [] })
  const [dateStart, setDateStart] = useState({ from: { year, month: month + 1, day }, to: utils().getToday() })
  const [dateStartCat, setDateStartCat] = useState({ from: { year, month: month + 1, day }, to: utils().getToday() })
  const [dateStartServ, setDateStartServ] = useState({ from: { year, month: month + 1, day }, to: utils().getToday() })
  const [dateStartExp, setDateStartExp] = useState({ from: { year, month: month + 1, day }, to: utils().getToday() })
  const [dateStartCli, setDateStartCli] = useState({ from: { year, month: month + 1, day }, to: utils().getToday() })
  const [dateStartCity, setDateStartCity] = useState({ from: { year, month: month + 1, day }, to: utils().getToday() })
  const [dateStartRegion, setDateStartRegion] = useState({ from: { year, month: month + 1, day }, to: utils().getToday() })

  const colors = [
    'rgba(255, 27, 27, 1)',
    'rgba(255, 160, 0, 1)',
    'rgba(253, 197, 52, 1)',
    'rgba(62, 178, 67, 1)',
    'rgba(73, 195, 157, 1)',
    'rgba(110, 161, 248, 1)',
    'rgba(70, 102, 222, 1)'
  ];


  const parseDataSet = (data = [], cb = undefined, barPercentage = 0.5, color = colors, border = 2, label = "") => {
    if (cb != undefined) cb({
      labels: data.map(e => e.label),
      datasets: [{
        barPercentage: barPercentage,
        label: label,
        data: data.map(e => e.total),
        backgroundColor: data.map((e, i) => e.color ? e.color : colors[i]),
        borderColor: data.map((e, i) => e.color ? e.color : colors[i]),
        borderWidth: border,
        borderRadius: 10,
      }]
    })
  }



  const [options, setOptions] = useState({
    responsive: true,
    maintainAspectRatio: false,
    legend: { display: false },
    tooltips: {
      mode: 'index',
      intersect: false,
      callbacks: {
        label: function (t, d) {
          return new Intl.NumberFormat("es-CL", { style: "currency", currency: "CLP" }).format(t.yLabel)
        },
        title: function (t, d) {
          return t[0].label;
        }
      }
    },

    scales: {
      yAxes: [{
        scaleLabel: {
          display: false,
          labelString: "TOTALES",
        },
        ticks: {
          beginAtZero: true,
          min: 0,
          callback: function (value, index, values) {
            return new Intl.NumberFormat("es-CL", { style: "currency", currency: "CLP" }).format(value)
          }
        }
      }],
      xAxes: [{
        scaleLabel: {
          display: false,
          labelString: "OCUPACIÓN DE LA AGENDA",
        },
        ticks: {
          min: 0,
        }
      }]
    }
  })


  const getSales = (date) => {
    axios.post('/api/sales/global', date ? date : dateStart)
      .then(res => {
        parseDataSet(res.data.data, data => setData(data))
      })
      .catch(err => console.log(err));
  }

  const getTopTenCategory = (date) => {
    axios.post('/api/sales/top/ten/category', date ? date : dateStartCat)
      .then(res => parseDataSet(res.data.data, data => setDataCat(data), 1))
      .catch(err => console.log(err));
  }

  const getTopTenServices = (date) => {
    axios.post('/api/sales/top/ten/service', date ? date : dateStartServ)
      .then(res => parseDataSet(res.data.data, data => setDataServ(data), 1))
      .catch(err => console.log(err));
  }

  const getTopTenExpert = (date) => {
    axios.post('/api/sales/top/ten/expert', date ? date : dateStartExp)
      .then(res => parseDataSet(res.data.data, data => setDataExp(data), 1))
      .catch(err => console.log(err));
  }

  const getTopTenClient = (date) => {
    axios.post('/api/sales/top/ten/client', date ? date : dateStartCli)
      .then(res => parseDataSet(res.data.data, data => setDataClient(data), 1))
      .catch(err => console.log(err));
  }

  const getTopTenCity = (date) => {
    axios.post('/api/sales/top/ten/city', date ? date : dateStartCity)
      .then(res => parseDataSet(res.data.data, data => setDataCity(data), 1))
      .catch(err => console.log(err));
  }

  const getTopTenRegion = (date) => {
    axios.post('/api/sales/top/ten/region', date ? date : dateStartRegion)
      .then(res => parseDataSet(res.data.data, data => setDataRegion(data), 1))
      .catch(err => console.log(err));
  }

  return (
    <Main>
      <div className="row">
        <div className="col-12">
          <Header title="Bienvenido a tu Panel" />
        </div>
      </div>
      <div className="row">
        <div className="col-12 col-md-12">
          <Card title="Gráfico de ventas" className="p-5" toolbar={<DatePicker
            value={dateStart}
            onChange={(s) => {
              setDateStart(s);
              if (s.to != null) getSales(s);
            }}
            inputPlaceholder="Selecciona un rango de fecha"
            shouldHighlightWeekends
            calendarClassName="responsive-calendar"
            maximumDate={utils().getToday()}
            locale={localeEs}
          />}>
            <Bar
              data={data}
              width={200}
              height={300}
              options={options}
            />
          </Card>
        </div>
      </div>

      <div className="row">
        <div className="col-12 col-md-6">
          <Card title="Ranking de ventas por categoria (top 10)" className="p-2" toolbar={<DatePicker
            value={dateStartCat}
            onChange={(s) => {
              setDateStartCat(s);
              if (s.to != null) getTopTenCategory(s);
            }}
            inputPlaceholder="Selecciona un rango de fecha"
            shouldHighlightWeekends
            calendarClassName="responsive-calendar"
            maximumDate={utils().getToday()}
            locale={localeEs}
          />}>
            <HorizontalBar data={dataCat} options={{
              responsive: true,
              maintainAspectRatio: true,
              legend: { display: false },
              scales: {
                xAxes: [{
                  scaleLabel: { display: false },
                  ticks: {
                    beginAtZero: true,
                    min: 0,
                    callback: (value, index, values) => new Intl.NumberFormat("es-CL", { style: "currency", currency: "CLP" }).format(value)
                  }
                }],
                yAxes: [{ scaleLabel: { display: false }, ticks: { min: 0 } }]
              },
              tooltips: {
                mode: 'index',
                intersect: false,
                callbacks: {
                  label: function (t, d) {
                    return new Intl.NumberFormat("es-CL", { style: "currency", currency: "CLP" }).format(t.xLabel)
                  },
                  title: function (t, d) {
                    return t[0].label;
                  }
                }
              }
            }} />
          </Card>
        </div>

        <div className="col-12 col-md-6">
          <Card title="Ranking de ventas por servicio (top 10)" className="p-2" toolbar={<DatePicker
            value={dateStartServ}
            onChange={(s) => {
              setDateStartServ(s);
              if (s.to != null) getTopTenServices(s)
            }}
            inputPlaceholder="Selecciona un rango de fecha"
            shouldHighlightWeekends
            calendarClassName="responsive-calendar"
            maximumDate={utils().getToday()}
            locale={localeEs}
          />}>
            <HorizontalBar data={dataServ} options={{
              responsive: true,
              maintainAspectRatio: true,
              legend: { display: false },
              scales: {
                xAxes: [{
                  scaleLabel: { display: false },
                  ticks: {
                    beginAtZero: true,
                    min: 0,
                    callback: (value, index, values) => new Intl.NumberFormat("es-CL", { style: "currency", currency: "CLP" }).format(value)
                  }
                }],
                yAxes: [{ scaleLabel: { display: false }, ticks: { min: 0 } }]
              },
              tooltips: {
                mode: 'index',
                intersect: false,
                callbacks: {
                  label: function (t, d) {
                    return new Intl.NumberFormat("es-CL", { style: "currency", currency: "CLP" }).format(t.xLabel)
                  },
                  title: function (t, d) {
                    return t[0].label;
                  }
                }
              }
            }} />
          </Card>
        </div>

        <div className="col-12 col-md-6">
          <Card title="Ranking de ventas por proveedor (top 10)" className="p-2" toolbar={<DatePicker
            value={dateStartExp}
            onChange={(s) => {
              setDateStartExp(s);
              if (s.to != null) getTopTenExpert(s);
            }}
            inputPlaceholder="Selecciona un rango de fecha"
            shouldHighlightWeekends
            calendarClassName="responsive-calendar"
            maximumDate={utils().getToday()}
            locale={localeEs}
          />}>
            <HorizontalBar data={dataExp} options={{
              responsive: true,
              maintainAspectRatio: true,
              legend: { display: false },
              scales: {
                xAxes: [{
                  scaleLabel: { display: false },
                  ticks: {
                    beginAtZero: true,
                    min: 0,
                    callback: (value, index, values) => new Intl.NumberFormat("es-CL", { style: "currency", currency: "CLP" }).format(value)
                  }
                }],
                yAxes: [{ scaleLabel: { display: false }, ticks: { min: 0 } }]
              },
              tooltips: {
                mode: 'index',
                intersect: false,
                callbacks: {
                  label: function (t, d) {
                    return new Intl.NumberFormat("es-CL", { style: "currency", currency: "CLP" }).format(t.xLabel)
                  },
                  title: function (t, d) {
                    return t[0].label;
                  }
                }
              }
            }} />
          </Card>
        </div>

        <div className="col-12 col-md-6">
          <Card title="Ranking de ventas por cliente (top 10)" className="p-2" toolbar={<DatePicker
            value={dateStartCli}
            onChange={(s) => {
              setDateStartCli(s);
              if (s.to != null) getTopTenClient(s)
            }}
            inputPlaceholder="Selecciona un rango de fecha"
            shouldHighlightWeekends
            calendarClassName="responsive-calendar"
            maximumDate={utils().getToday()}
            locale={localeEs}
          />}>
            <HorizontalBar data={dataClient} options={{
              responsive: true,
              maintainAspectRatio: true,
              legend: { display: false },
              scales: {
                xAxes: [{
                  scaleLabel: { display: false },
                  ticks: {
                    beginAtZero: true,
                    min: 0,
                    callback: (value, index, values) => new Intl.NumberFormat("es-CL", { style: "currency", currency: "CLP" }).format(value)
                  }
                }],
                yAxes: [{ scaleLabel: { display: false }, ticks: { min: 0 } }]
              },
              tooltips: {
                mode: 'index',
                intersect: false,
                callbacks: {
                  label: function (t, d) {
                    return new Intl.NumberFormat("es-CL", { style: "currency", currency: "CLP" }).format(t.xLabel)
                  },
                  title: function (t, d) {
                    return t[0].label;
                  }
                }
              }
            }} />
          </Card>
        </div>

        <div className="col-12 col-md-6">
          <Card title="Ranking de ventas por ciudad (top 10)" className="p-2" toolbar={<DatePicker
            value={dateStartCity}
            onChange={(s) => {
              setDateStartCity(s);
              if (s.to != null) getTopTenCity(s);
            }}
            inputPlaceholder="Selecciona un rango de fecha"
            shouldHighlightWeekends
            calendarClassName="responsive-calendar"
            maximumDate={utils().getToday()}
            locale={localeEs}
          />}>
            <HorizontalBar data={dataCity} options={{
              responsive: true,
              maintainAspectRatio: true,
              legend: { display: false },
              scales: {
                xAxes: [{
                  scaleLabel: { display: false },
                  ticks: {
                    beginAtZero: true,
                    min: 0,
                    callback: (value, index, values) => new Intl.NumberFormat("es-CL", { style: "currency", currency: "CLP" }).format(value)
                  }
                }],
                yAxes: [{ scaleLabel: { display: false }, ticks: { min: 0 } }]
              },
              tooltips: {
                mode: 'index',
                intersect: false,
                callbacks: {
                  label: function (t, d) {
                    return new Intl.NumberFormat("es-CL", { style: "currency", currency: "CLP" }).format(t.xLabel)
                  },
                  title: function (t, d) {
                    return t[0].label;
                  }
                }
              }
            }} />
          </Card>
        </div>

        <div className="col-12 col-md-6">
          <Card title="Ranking de ventas por región (top 10)" className="p-2" toolbar={<DatePicker
            value={dateStartRegion}
            onChange={(s) => {
              setDateStartRegion(s);
              if (s.to != null) getTopTenRegion(s);
            }}
            inputPlaceholder="Selecciona un rango de fecha"
            shouldHighlightWeekends
            calendarClassName="responsive-calendar"
            maximumDate={utils().getToday()}
            locale={localeEs}
          />}>
            <HorizontalBar data={dataRegion} options={{
              responsive: true,
              maintainAspectRatio: true,
              legend: { display: false },
              scales: {
                xAxes: [{
                  scaleLabel: { display: false },
                  ticks: {
                    beginAtZero: true,
                    min: 0,
                    callback: (value, index, values) => new Intl.NumberFormat("es-CL", { style: "currency", currency: "CLP" }).format(value)
                  }
                }],
                yAxes: [{ scaleLabel: { display: false }, ticks: { min: 0 } }]
              },
              tooltips: {
                mode: 'index',
                intersect: false,
                callbacks: {
                  label: function (t, d) {
                    return new Intl.NumberFormat("es-CL", { style: "currency", currency: "CLP" }).format(t.xLabel)
                  },
                  title: function (t, d) {
                    return t[0].label;
                  }
                }
              }
            }} />
          </Card>
        </div>
      </div>
    </Main>
  )
}

export default connect(Dashboard);

