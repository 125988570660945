import { createStore, combineReducers } from 'redux';
import reducers from './reducer';
import actions from './actions';
import { connect as conn } from 'react-redux';


export const store = createStore(combineReducers({
    store: reducers,
}));

const mapStateToProps = (state, ownProps) => {
    return state;
}

const mapDispatchToProps = (dispatch, ownProps) => {

    return {
        setVersion: (payload) => dispatch(actions.setVersion(payload)),
        setSidebar: (payload) => dispatch(actions.setSidebar(payload)),
        setSidesheet: (payload) => dispatch(actions.setSidesheet(payload)),
        setHead: (payload) => dispatch(actions.setHead(payload)),
        setTools: (payload) => dispatch(actions.setTools(payload)),
        setViewport: (payload) => dispatch(actions.setViewport(payload)),
        setGoback: (payload) => dispatch(actions.setGoback(payload)),
        setActiveMenu: (payload) => dispatch(actions.setActiveMenu(payload)),
        setFormType: (payload) => dispatch(actions.setFormType(payload)),
        getYears: (payload) => {
            let init = 2013;
            let end = new Date().getFullYear();
            let result = [];
            while (init <= end) {
                result.push(init);
                init++
            };
            return result;
        },
        notify: (payload = {}) => {
            let noty = {}
            noty['type'] = payload['type'] != undefined ? payload['type'] : 'info';
            noty['title'] = payload['title'] != undefined ? payload['title'] : 'Información';
            noty['text'] = payload['text'] != undefined ? payload['text'] : '';
            noty['time'] = payload['time'] != undefined ? parseInt(payload['time']) : 2000;
            noty['id'] = payload['id'] != undefined ? parseInt(payload['id']) : undefined;
            dispatch(actions.pushNotification(noty));
        },
        notifyRemove: (payload = {}) => dispatch(actions.removeNotification(payload)),
        setUserIdActivate: (payload = {}) => dispatch(actions.setUserIdActivate(payload)),
        setCategories: (payload = {}) => dispatch(actions.setCategories(payload)),
        setGroups: (payload = {}) => dispatch(actions.setGroups(payload)),
        setExperts: (payload = {}) => dispatch(actions.setExperts(payload)),
        setClients: (payload = {}) => dispatch(actions.setClients(payload)),
        setMasterServices: (payload) => dispatch(actions.setMasterServices(payload)),
        
    }
}

export const connect = (component) => {
    return conn(mapStateToProps, mapDispatchToProps)(component)
}