import React from "react";
import { Redirect } from 'react-router-dom';
import jwt_decode from "jwt-decode"
import { getUrl } from '../utils'

const key = 'tktk';

const isToken = () => {
    return getToken() != null || undefined ? true : false;
}

export const getToken = () => {
    return window.localStorage.getItem(key);
}

export const setToken = (value) => {
    window.localStorage.setItem(key, value)
}

export const Middleware = (Component) => {
    return isToken() ? <Component /> : <Redirect to="/login" />
}

export const MiddlewarePermission = (Component, roles = [], redirect = "/") => {
    const token = getToken();
    const payload = jwt_decode(token);
    return roles.includes(payload.user.role) ? <Component /> : <Redirect to={redirect} />;
}

export const getUserId = () => {
    const token = getToken();
    const payload = jwt_decode(token);

    return {
        userId: payload.user._id,
        rut: payload.user.rut,
        name: payload.user.name + " " + payload.user.lastname,
        rol: payload.user.role,
        avatar: getUrl(payload.user.avatar),
        email: payload.user.email,
        user: payload.user,
        token: token
    }
}

export const closeSession = () => {
    window.localStorage.removeItem(key);
    window.location.href = "/";
}


export const permission = (current, roles = []) => {
    return roles.length == 0 ? true : roles.includes(current);
}