import React, { useState, useEffect } from "react";
// Componentes
import axios from 'axios'
import Main from "../../components/Main";
import Header from '../../components/Header';
import { Card } from '../../components/card';
import { connect } from '../../store';
import { BtnDelete, BtnEdit, BtnSee, SecondaryBtn } from '../../components/buttons'
import Table from '../../components/table';
import { ModalSimple } from '../../components/modal'
import { Row, Col } from '../../components/grid'
import { parseDataSelect } from '../../utils'
import { SelectLight } from '../../components/input'
import fileDownload from 'js-file-download'
import './style.css';
// FloatButton
const SugerenciasServices = (props) => {
  const [see, setSee] = useState(false);
  const [remove, setRemove] = useState(false);
  const [current, setCurrent] = useState(false);
  const [edit, setEdit] = useState(null);
  const [nuevo, setNuevo] = useState(false);
  const [data, setData] = useState([])
  const [columns] = useState([
    { key: 'custom', text: 'Fecha', elem: e => new Date(e.createdAt).toLocaleDateString() },
    { key: 'custom', text: 'Nombre', elem: e => e.name },
    { key: 'custom', text: 'Descripción', elem: e => e.desc },
    { key: 'custom', text: 'Codigo categoria', elem: e => e.cat && e.cat.name ? e.cat.name : "Sin categoria" },
    { key: 'min', text: 'Precio Minimo' },
    { key: 'max', text: 'Precio Maximo' },
    { key: 'custom', text: 'Proveedor', elem: e => e.expert && e.expert.name ? e.expert.name + " " + e.expert.lastname : "Sin proveedor" },
    { key: 'custom', text: 'Email', elem: e => e.expert && e.expert.email ? e.expert.email : "Sin proveedor" },
    { key: 'custom', text: 'Teléfono', elem: e => e.expert && e.expert.phone ? e.expert.phone + " " + e.expert.lastname : "Sin proveedor" },
  ])

  useEffect(() => {
    axios.get('/api/recommended/service')
      .then(res => setData(parseDataSelect(res.data.data, '_id', 'name')))
      .catch(err => console.log(err))
  }, [])


  const exportsDataCSV = (dataTable) => {
    axios.post(`/api/exports/csv/suggestion`, { data: dataTable })
      .then(res => {
        fileDownload(res.data.data, res.data.fileName);
      })
      .catch(err => console.log(err))
  }

  const exportsDataXLS = (dataTable) => {
    axios.post(`/api/exports/xls/suggestion`, { data: dataTable })
      .then(res => {
        fileDownload(res.data.data, res.data.fileName);
      })
      .catch(err => console.log(err))
  }

  return (
    <Main>
      <Row>
        <Col>
          <Card title="Sugerencias de servicios" button={false} textButton="Nuevo servicio" onClick={() => setNuevo(true)}>
            <Row>
              <Col>
                <Table columns={columns} data={data} background="#FF8B03" onExportsCSV={data => exportsDataCSV(data)} keyID="_id" onExportsXLS={data => exportsDataXLS(data)} pdf={false} paginationClass="table-buttons" pagination={false} />
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
      {/* <ModalSimple width={"800px"} padding={20} open={see} onClose={setSee} cancel={true} textCancel="Modificar" accept={true} textAccept="Aceptar" onCancel={update}>
        <Row>
          <Col>
            <h4 className="mb-4">Datos de la categoria</h4>
          </Col>
          <Col>
            <TableEdit current={current} onChange={(d) => setEdit(d)}/>
          </Col>
        </Row>
      </ModalSimple>

      <ModalSimple padding={20} open={remove} onClose={setRemove} cancel={true} textCancel="Cancelar" accept={true} textAccept="Aceptar" onClick={removeItem}>
        <Row>
          <Col>
            <h5>¿Esta seguro de eliminar la categoria {current.name}?</h5>
          </Col>
        </Row>
      </ModalSimple>

      <NewCatServ categories={props.store.categories} open={nuevo} onClosed={setNuevo} accept={true} /> */}
    </Main>
  )
}

export default connect(SugerenciasServices);


const TableEdit = connect((props) => {

  const [data, setData] = useState({})

  useEffect(() => {
    setData(props.current);
  }, [props.current])

  const setDataKey = (key, value) => {
    let current = Object.assign({}, data);
    current[key] = value;
    setData(current);

    if (props.onChange != undefined) props.onChange(current);
  }

  return (
    <table className="table table-vertical-center ">
      <tbody>
        <tr>
          <td><strong>Nombre:</strong></td>
          <td><input style={{ margin: 0, padding: 0 }} type="text" className="form-control" value={data && data.name} onChange={v => setDataKey('name', v.target.value)} /></td>
        </tr>
        <tr>
          <td><strong>Categoria:</strong></td>
          <td><SelectLight disabled={true} options={props.store.categories} placeholder="Seleccionar una categoria" value={data.cat && { value: data.cat._id, label: data.cat.name }} onChange={v => setDataKey('cat', v)} /></td>
        </tr>
        <tr>
          <td><strong>Minimo:</strong></td>
          <td>
            <td><input style={{ margin: 0, padding: 0 }} type="number" className="form-control" value={data && data.min} onChange={v => setDataKey('min', v.target.value)} /></td>
          </td>
        </tr>
        <tr>
          <td><strong>Maximo:</strong></td>
          <td>
            <td><input style={{ margin: 0, padding: 0 }} type="number" className="form-control" value={data && data.max} onChange={v => setDataKey('max', v.target.value)} /></td>
          </td>
        </tr>
        <tr>
          <td className="pv-15"><strong>Codigo interno:</strong></td>
          <td className="pv-15">{data && data._id}</td>
        </tr>
      </tbody>
    </table>
  )
})



const NewCatServ = connect((props) => {
  const [name, setName] = useState(null)
  const [cat, setCat] = useState(null)
  const [min, setMin] = useState(null);
  const [max, setMax] = useState(null);
  const [err, setErr] = useState(false)
  const [errMsg, setErrMsg] = useState('')

  const newErr = (status, message = '') => {
    setErr(status);
    setErrMsg(message);
  }

  const close = () => {
    if (props.onClosed != undefined) props.onClosed(false);
  }

  const save = () => {
    if (name == null || name.length == 0 || name === '') {
      newErr(true, 'El campo nombre es requerido');
      return false;
    } else newErr(false);

    if (cat == null) {
      newErr(true, 'Debe seleccionar una categoria');
      return false;
    } else newErr(false);

    if (parseInt(max) < parseInt(min)) return newErr(true, 'El Maximo no puede ser menor al Minimo');
    else newErr(false);

    if (parseInt(min) == parseInt(max)) return newErr(true, 'El Minimo no puede ser mayor al Maximo');
    else newErr(false);

    axios.post('/api/master/service', { name: name, cat: cat.value, min: min, max: max })
      .then(res => {
        props.notify({ type: "success", title: "Crear categoria de servicio", text: "Categoria creada" });
        if (props.onClosed != undefined) props.onClosed(false);
      })
      .catch(err => {
        props.notify({ type: "error", title: "Crear categoria de servicio", text: "Error al crear la categoria" })
      })
  }

  return (
    <ModalSimple width={"800px"} padding={20} autoClose={false} open={props.open} onClose={close} cancel={props.cancel} textCancel={props.textCancel} accept={props.accept} textAccept={props.textAccept} onClick={save}>
      <Row>
        <Col>
          <h4 className="mb-4">Nuevo Servicio</h4>
          <table className="table table-vertical-center">
            <tbody>
              <tr>
                <td><strong>Nombre:</strong></td>
                <td><input placeholder="Nombre" style={{ margin: 0, padding: 0 }} type="text" className="form-control" value={name} onChange={v => setName(v.target.value)} /></td>
              </tr>
              <tr>
                <td><strong>Categoria:</strong></td>
                <td>
                  <SelectLight disabled={true} options={props.store.categories} placeholder="Seleccionar categoria" value={cat} onChange={v => setCat(v)} />
                </td>
              </tr>
              <tr>
                <td><strong>Minimo:</strong></td>
                <td>
                  <input placeholder="Precio minimo" style={{ margin: 0, padding: 0 }} type="number" className="form-control" value={min} onChange={v => setMin(v.target.value)} />
                </td>
              </tr>
              <tr>
                <td><strong>Maximo:</strong></td>
                <td>

                  <input placeholder="Precio maximo" style={{ margin: 0, padding: 0 }} type="number" className="form-control" value={max} onChange={v => setMax(v.target.value)} />
                </td>
              </tr>
            </tbody>
          </table>
        </Col>
        <Col className="text-center">{err ? <p style={{ color: "#ff4444" }}>{errMsg}</p> : null}</Col>
      </Row>
    </ModalSimple>
  )
})

NewCatServ.defaultProps = {
  open: false,
  cancel: true,
  accept: false,
  textCancel: "Cancelar",
  textAccept: 'Aceptar'
}