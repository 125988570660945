import React, { useState, useEffect } from "react";
// Componentes
import axios from 'axios'
import Main from "../../components/Main";
import Header from '../../components/Header';
import { Card } from '../../components/card';
import { connect } from '../../store';
import { Row, Col } from '../../components/grid'
import { PrimaryBtn, SecondaryBtn } from '../../components/buttons'
// import './style.css';

const Dashboard = (props) => {

    const [rFeeApp, setRfeeApp] = useState(0);
    const [rFeeAnt, setRfeeAnt] = useState(0);
    const [eFeeApp, setEfeeApp] = useState(0);
    const [eFeeAnt, setEfeeAnt] = useState(0);
    const [nFeeApp, setNfeeApp] = useState(0);
    const [nFeeAnt, setNfeeAnt] = useState(0);
    const [uFeeApp, setUfeeApp] = useState(0);
    const [uFeeAnt, setUfeeAnt] = useState(0);
    const [reserv, setReserv] = useState(0);
    const [mp, setMp] = useState(0);
    const [iva, setIva] = useState(0);
    const [dNormal, setDNormal] = useState(0);
    const [dUrgente, setDUrgente] = useState(0);
    const [dEconomica, setDEconomica] = useState(0);
    const [retencion, setRetencion] = useState(0);

    useEffect(() => {
        axios.get('/api/parameters/rate')
            .then(res => {
                let data = res.data.data;
                setRfeeAnt(data.feeAnticipacion.recurrente);
                setEfeeAnt(data.feeAnticipacion.economica);
                setNfeeAnt(data.feeAnticipacion.normal);
                setUfeeAnt(data.feeAnticipacion.urgente);
                setRfeeApp(data.feeApp.recurrente);
                setEfeeApp(data.feeApp.economica);
                setNfeeApp(data.feeApp.normal);
                setUfeeApp(data.feeApp.urgente);
                setReserv(data.reserv)
                setMp(data.mp);
                setIva(data.iva);
                setDEconomica(data.days.economica);
                setDNormal(data.days.normal);
                setDUrgente(data.days.urgente);
                setRetencion(data.retencion)
            })
            .catch(err => console.log(err))
    }, [])


    const saveParams = () => {
        let body = {
            ant_economica: parseFloat(eFeeAnt),
            ant_normal: parseFloat(nFeeAnt),
            ant_recurrente: parseFloat(rFeeAnt),
            ant_urgente: parseFloat(uFeeAnt),
            day_urgente: parseFloat(dUrgente),
            day_normal: parseFloat(dNormal),
            day_economica: parseFloat(dEconomica),
            iva: parseFloat(iva),
            app_economica: parseFloat(eFeeApp),
            app_normal: parseFloat(nFeeApp),
            app_recurrente: parseFloat(rFeeApp),
            app_urgente: parseFloat(uFeeApp),
            reserv: parseFloat(reserv),
            mp: parseFloat(mp),
            retencion: parseFloat(retencion)
        };

        axios.post('/api/parameters/rate', body)
            .then(res => props.notify({ type: "success", title: "Ajustes de venta", text: "Actualizado" }))
            .catch(err => props.notify({ type: "error", title: "Ajustes de venta", text: "No se pudo actualizar, favor intente mas tarde" }))
    }

    return (
        <Main>
            <Row>
                <Col>
                    <Header title="Parametros del sistema" />
                </Col>
            </Row>
            <Row>
                <Col md={12}>
                    <Card title="Ajustes de venta" style={{ padding: 20 }}>
                        <table className="table">
                            <thead>
                                <th>Tarifa</th>
                                <th style={{ textAlign: "center", verticalAlign: "middle", minWidth: "100%" }}>Fee Proveedor</th>
                                <th style={{ textAlign: "center", verticalAlign: "middle", minWidth: "100%" }}>Fee Anticipación Cliente</th>
                                <th style={{ textAlign: "left", verticalAlign: "middle", minWidth: "100%" }}>Días</th>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Recurrente</td>
                                    <td style={{ textAlign: "center", verticalAlign: "middle", minWidth: "100%" }}><InputTarifa value={rFeeApp} onChange={setRfeeApp} /></td>
                                    <td style={{ textAlign: "center", verticalAlign: "middle", minWidth: "100%" }}><InputTarifa value={rFeeAnt} onChange={setRfeeAnt} /></td>
                                    <td style={{ textAlign: "left", verticalAlign: "middle", minWidth: "100%" }}> No aplica</td>
                                </tr>
                                <tr>
                                    <td>Economica</td>
                                    <td style={{ textAlign: "center", verticalAlign: "middle", minWidth: "100%" }}><InputTarifa value={eFeeApp} onChange={setEfeeApp} /></td>
                                    <td style={{ textAlign: "center", verticalAlign: "middle", minWidth: "100%" }}><InputTarifa value={eFeeAnt} onChange={setEfeeAnt} /></td>
                                    <td style={{ textAlign: "left", verticalAlign: "middle", minWidth: "100%" }}>Resto de días</td>
                                </tr>
                                <tr>
                                    <td>Normal</td>
                                    <td style={{ textAlign: "center", verticalAlign: "middle", minWidth: "100%" }}><InputTarifa value={nFeeApp} onChange={setNfeeApp} /></td>
                                    <td style={{ textAlign: "center", verticalAlign: "middle", minWidth: "100%" }}><InputTarifa value={nFeeAnt} onChange={setNfeeAnt} /></td>
                                    <td style={{ textAlign: "left", verticalAlign: "middle", minWidth: "100%" }}><InputTarifa step="1" value={dNormal} onChange={setDNormal} firts="Hasta" firm="Días" /></td>
                                </tr>
                                <tr>
                                    <td>Urgente</td>
                                    <td style={{ textAlign: "center", verticalAlign: "middle", minWidth: "100%" }}><InputTarifa value={uFeeApp} onChange={setUfeeApp} /></td>
                                    <td style={{ textAlign: "center", verticalAlign: "middle", minWidth: "100%" }}><InputTarifa value={uFeeAnt} onChange={setUfeeAnt} /></td>
                                    <td style={{ textAlign: "left", verticalAlign: "middle", minWidth: "100%" }}><InputTarifa step="1" value={dUrgente} onChange={setDUrgente} firts="Hasta" firm="Días" /></td>
                                </tr>
                            </tbody>
                        </table>

                        <Row>
                            <Col>
                                <hr />
                                <tr>
                                    <td style={{ width: 150, padding: 8 }}>Reserva</td>
                                    <td style={{ textAlign: "left", verticalAlign: "middle" }}><InputTarifa value={reserv} onChange={setReserv} /></td>
                                </tr>
                                <tr>
                                    <td style={{ width: 150, padding: 8 }}>Medio de pago</td>
                                    <td style={{ textAlign: "left", verticalAlign: "middle" }}><InputTarifa step="0.01" value={mp} onChange={setMp} /></td>
                                </tr>
                                <tr>
                                    <td style={{ width: 150, padding: 8 }}>IVA</td>
                                    <td style={{ textAlign: "left", verticalAlign: "middle" }}><InputTarifa value={iva} onChange={setIva} /></td>
                                </tr>
                                <tr>
                                    <td style={{ width: 150, padding: 8 }}>Rentención boleta</td>
                                    <td style={{ textAlign: "left", verticalAlign: "middle" }}><InputTarifa value={retencion} onChange={setRetencion} /></td>
                                </tr>
                                <hr />
                            </Col>
                        </Row>
                        <Row>
                            <Col className="text-right pt-5">
                                <SecondaryBtn onClick={() => saveParams()}>Guardar</SecondaryBtn>
                            </Col>
                        </Row>
                    </Card>
                </Col>
            </Row>
        </Main>
    )
}

export default connect(Dashboard);

const InputTarifa = (props) => {

    const change = (v) => {
        if (props.onChange != undefined) props.onChange(v.target.value);
    }

    return (
        <>
            <span style={{ marginRight: 5 }}>{props.firts}</span> <input step={props.step} style={{ maxWidth: 90, borderRadius: 4, border: "1px solid #ccc", textAlign: "center" }} min={0} max={100} type="number" value={props.value} onChange={change} /> <span style={{ marginLeft: 5 }}>{props.firm}</span>
        </>
    )
}

InputTarifa.defaultProps = {
    value: 0,
    firm: "%",
    firts: "",
    step: "0.1"
}