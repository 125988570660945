import React, { useState, useEffect } from "react";
// Componentes
import axios from 'axios'
import Main from "../../components/Main";
import Header from '../../components/Header';
import { Card } from '../../components/card';
import { connect } from '../../store';
import { BtnDelete, BtnEdit, BtnSee, SecondaryBtn } from '../../components/buttons'
import Table from '../../components/table';
import { ModalSimple } from '../../components/modal'
import { Row, Col } from '../../components/grid'
import { parseDataSelect } from '../../utils'
import { SelectLight } from '../../components/input'
import fileDownload from 'js-file-download'
import './style.css';
// FloatButton
const CatServices = (props) => {
  const [see, setSee] = useState(false);
  const [remove, setRemove] = useState(false);
  const [current, setCurrent] = useState(false);
  const [edit, setEdit] = useState(null);
  const [nuevo, setNuevo] = useState(false);

  const [columns] = useState([
    { key: 'name', text: 'Nombre' },
    { key: 'cat.name', text: 'Categoria' },
    { key: 'min', text: 'Precio Minimo' },
    { key: 'max', text: 'Precio Maximo' },
    { key: '_id', text: 'Cod. Interno' },
    {
      key: 'controls', text: 'Acciones', data: null, elem: (cat) => {
        return <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
          <BtnEdit onClick={() => { setCurrent(cat); setSee(true) }} />
          <BtnDelete onClick={() => { setCurrent(cat); setRemove(true) }} />
        </div>
      }
    }
  ])

  useEffect(() => {
    axios.get('/api/master/service')
      .then(res => {
        props.setMasterServices(parseDataSelect(res.data.data, '_id', 'name'))
      })
      .catch(err => console.log(err))
  }, [])

  const removeItem = () => {

    axios.delete(`/api/master/service/${current._id}`)
      .then(response => {
        props.notify({ type: "success", title: "Eliminar grupo", text: "Grupo eliminado" });
      })
      .catch(err => {
        props.notify({ type: "error", title: "Eliminar grupo", text: "No se pudo eliminar el grupo" })
      })
  }

  const update = () => {

    axios.put(`/api/master/service/${current._id}`, {
      name: edit.name,
      cat: edit.cat._id,
      min: edit.min,
      max: edit.max
    })
      .then(res => {
        props.notify({ type: "success", title: "Actualizar servicio", text: "Servicio actualizado" });
        setEdit(null);
      })
      .catch(err => {
        console.log(err);
        props.notify({ type: "error", title: "Actualizar servicio", text: "No se pudo actualizar al servicio" })
      })
  }

  const exportsDataCSV = (dataTable) => {
    axios.post(`/api/exports/csv/master`, { data: dataTable })
      .then(res => {
        fileDownload(res.data.data, res.data.fileName);
      })
      .catch(err => console.log(err))
  }

  const exportsDataXLS = (dataTable) => {
    axios.post(`/api/exports/xls/master`, { data: dataTable })
      .then(res => {
        fileDownload(res.data.data, res.data.fileName);
      })
      .catch(err => console.log(err))
  }

  const updateManual = () => {
    axios.post(`/api/task/update`)
      .then(res => {
        props.notify({ type: "success", title: "Actualizar", text: "Servicios actualizados" });
      })
      .catch(err => console.log(err))
  }

  return (
    <Main>
      <Row>
        <Col>
          <Card title="Servicios maestros" button={true} textButton="Nuevo servicio" onClick={() => setNuevo(true)} secondButton={true} textSecond="Actualizar" onClickSecondary={updateManual}>
            <Row>
              <Col>
                <Table columns={columns} data={props.store.masterServices} background="#FF8B03" onExportsCSV={data => exportsDataCSV(data)} keyID="_id" onExportsXLS={data => exportsDataXLS(data)} pdf={false} pagination={false} />
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
      <ModalSimple width={"800px"} padding={20} open={see} onClose={setSee} cancel={true} textCancel="Modificar" accept={true} textAccept="Aceptar" onCancel={update}>
        <Row>
          <Col>
            <h4 className="mb-4">Datos de la categoria</h4>
          </Col>
          <Col>
            <TableEdit current={current} onChange={(d) => setEdit(d)} />
          </Col>
        </Row>
      </ModalSimple>

      <ModalSimple padding={20} open={remove} onClose={setRemove} cancel={true} textCancel="Cancelar" accept={true} textAccept="Aceptar" onClick={removeItem}>
        <Row>
          <Col>
            <h5>¿Esta seguro de eliminar la categoria {current.name}?</h5>
          </Col>
        </Row>
      </ModalSimple>

      <NewCatServ categories={props.store.categories} open={nuevo} onClosed={setNuevo} accept={true} />
    </Main>
  )
}

export default connect(CatServices);


const TableEdit = connect((props) => {

  const [data, setData] = useState({})

  useEffect(() => {
    setData(props.current);
  }, [props.current])

  const setDataKey = (key, value) => {
    let current = Object.assign({}, data);
    current[key] = value;
    setData(current);

    if (props.onChange != undefined) props.onChange(current);
  }

  return (
    <table className="table table-vertical-center ">
      <tbody>
        <tr>
          <td><strong>Nombre:</strong></td>
          <td><input style={{ margin: 0, padding: 0 }} type="text" className="form-control" value={data && data.name} onChange={v => setDataKey('name', v.target.value)} /></td>
        </tr>
        <tr>
          <td><strong>Categoria:</strong></td>
          <td><SelectLight disabled={true} options={props.store.categories} placeholder="Seleccionar una categoria" value={data.cat && { value: data.cat._id, label: data.cat.name }} onChange={v => setDataKey('cat', v)} /></td>
        </tr>
        <tr>
          <td><strong>Minimo:</strong></td>
          <td>
            <td><input style={{ margin: 0, padding: 0 }} type="number" className="form-control" value={data && data.min} onChange={v => setDataKey('min', v.target.value)} /></td>
          </td>
        </tr>
        <tr>
          <td><strong>Maximo:</strong></td>
          <td>
            <td><input style={{ margin: 0, padding: 0 }} type="number" className="form-control" value={data && data.max} onChange={v => setDataKey('max', v.target.value)} /></td>
          </td>
        </tr>
        <tr>
          <td className="pv-15"><strong>Codigo interno:</strong></td>
          <td className="pv-15">{data && data._id}</td>
        </tr>
      </tbody>
    </table>
  )
})



const NewCatServ = connect((props) => {
  const [name, setName] = useState(null)
  const [cat, setCat] = useState(null)
  const [min, setMin] = useState(null);
  const [max, setMax] = useState(null);
  const [err, setErr] = useState(false)
  const [errMsg, setErrMsg] = useState('')

  const newErr = (status, message = '') => {
    setErr(status);
    setErrMsg(message);
  }

  const close = () => {
    if (props.onClosed != undefined) props.onClosed(false);
  }

  const save = () => {
    if (name == null || name.length == 0 || name === '') {
      newErr(true, 'El campo nombre es requerido');
      return false;
    } else newErr(false);

    if (cat == null) {
      newErr(true, 'Debe seleccionar una categoria');
      return false;
    } else newErr(false);

    if (parseInt(max) < parseInt(min)) return newErr(true, 'El Maximo no puede ser menor al Minimo');
    else newErr(false);

    if (parseInt(min) == parseInt(max)) return newErr(true, 'El Minimo no puede ser mayor al Maximo');
    else newErr(false);

    axios.post('/api/master/service', { name: name, cat: cat.value, min: min, max: max })
      .then(res => {
        props.notify({ type: "success", title: "Crear servicio de servicio", text: "servicio creada" });
        setCat(null);
        setName(null);
        setMin(null);
        setMax(null);
        setErr(null);
        if (props.onClosed != undefined) props.onClosed(false);
      })
      .catch(err => {
        props.notify({ type: "error", title: "Crear servicio de servicio", text: "Error al crear la servicio" })
      })
  }

  return (
    <ModalSimple width={"800px"} padding={20} autoClose={false} open={props.open} onClose={close} cancel={props.cancel} textCancel={props.textCancel} accept={props.accept} textAccept={props.textAccept} onClick={save}>
      <Row>
        <Col>
          <h4 className="mb-4">Nuevo Servicio</h4>
          <table className="table table-vertical-center">
            <tbody>
              <tr>
                <td><strong>Nombre:</strong></td>
                <td><input placeholder="Nombre" style={{ margin: 0, padding: 0 }} type="text" className="form-control" value={name} onChange={v => setName(v.target.value)} /></td>
              </tr>
              <tr>
                <td><strong>Categoria:</strong></td>
                <td>
                  <SelectLight disabled={true} options={props.store.categories} placeholder="Seleccionar categoria" value={cat} onChange={v => setCat(v)} />
                </td>
              </tr>
              <tr>
                <td><strong>Minimo:</strong></td>
                <td>
                  <input placeholder="Precio minimo" style={{ margin: 0, padding: 0 }} type="number" className="form-control" value={min} onChange={v => setMin(v.target.value)} />
                </td>
              </tr>
              <tr>
                <td><strong>Maximo:</strong></td>
                <td>

                  <input placeholder="Precio maximo" style={{ margin: 0, padding: 0 }} type="number" className="form-control" value={max} onChange={v => setMax(v.target.value)} />
                </td>
              </tr>
            </tbody>
          </table>
        </Col>
        <Col className="text-center">{err ? <p style={{ color: "#ff4444" }}>{errMsg}</p> : null}</Col>
      </Row>
    </ModalSimple>
  )
})

NewCatServ.defaultProps = {
  open: false,
  cancel: true,
  accept: false,
  textCancel: "Cancelar",
  textAccept: 'Aceptar'
}