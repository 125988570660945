import React, { useState, useEffect } from "react";
import Endpoint from '../../Endpoint'
// Componentes
import axios from 'axios'
import Main from "../../components/Main";
import { Card } from '../../components/card';
import { connect } from '../../store';
import Table from '../../components/table';
import { Row, Col } from '../../components/grid'
import { parseDataSelect } from '../../utils'
import './style.css';


const Seguimiento = (props) => {
    const [data, setData] = useState([]);

    const [columns] = useState([
        { key: 'create', text: 'Fecha solicitud' },
        { key: 'client', text: 'Cliente' },
        { key: 'serv', text: 'Servicio' },
        { key: 'date', text: 'Fecha servicio' },
        { key: 'custom', text: 'Valor', elem: (s) => new Intl.NumberFormat("es-CL", { style: "currency", currency: "CLP" }).format(s.price) },
        { key: 'expert', text: 'Proveedor' },
        { key: 'custom', text: 'Estado', elem: (s) => <div> <div style={{ width: 8 , height: 8, background: s.colors, marginRight: 6, borderRadius:100, display:"inline-block" }}></div>{s.estado}</div> },
    ])

    useEffect(() => {
        getEvents();
    }, [])

    const getEvents = () => {
        axios.get('/api/flow')
            .then(res => {
                console.log(res.data);
                setData(parseDataSelect(res.data.data, '_id', 'name'));
            })
            .catch(err => console.log(err))
    }


    return (
        <Main>
            <Row>
                <Col>
                    <Card title="Seguimineto de solicitudes">
                        <Row>
                            <Col>
                                <Table pagination={true} columns={columns} data={data} background="#FF8B03" paginationClass="table-buttons" keyID="_id" exports={false} />
                            </Col>
                        </Row>
                    </Card>
                </Col>
            </Row>
        </Main>
    )
}

export default connect(Seguimiento);


