import React, { useState, useEffect, Fragment, useRef } from 'react'
import { default as Seleccion } from 'react-select';
import makeAnimated from 'react-select/animated';
import { IconCamera, startActive, startDisabled } from './icons'

import { Modal, DeleteFileModal } from "../../components/modal";
import { Col, Row } from '../grid'
import PhoneInput from 'react-phone-input-international'
import passwordValidator from 'password-validator';

import DatePicker, { utils } from 'react-modern-calendar-datepicker';
import { localeEs } from './locale'
import 'react-modern-calendar-datepicker/lib/DatePicker.css';
import './style.css';
import 'react-phone-input-international/lib/style.css'

import chroma from 'chroma-js'
import "flatpickr/dist/themes/material_orange.css";
import Flatpickr from "react-flatpickr";
import rangePlugin from "flatpickr/dist/plugins/rangePlugin";
import { Spanish } from "flatpickr/dist/l10n/es"


import EditIcon from '../../assets/icons/edit-pencil.svg'

var schema = new passwordValidator();
schema
    .is().min(8)
    .is().max(100)
    .has().uppercase()
    .has().lowercase()
    .has().digits()
    .has().not().spaces()
    .is().not();


const closeOneModal = (id) => {
    const modal = document.getElementById(id);
    modal.classList.remove('show');
    modal.setAttribute('aria-hidden', 'true');
    modal.setAttribute('style', 'display: none');
    const modalBackdrops = document.getElementsByClassName('modal-backdrop');
    document.body.removeChild(modalBackdrops[0]);
}

export const InputText = props => {
    const change = (v) => {
        if (props.onChange != undefined) props.onChange(v);
    }

    return (
        <div className="relative">
            <label className="inputTextLabel">{props.label}</label>
            {props.err ? <span className="inputTextErr">* Este campo es requerido</span> : null}
            <input type="text" disabled={props.disabled} className={props.err ? `inputText form-control error ${props.className}` : `inputText form-control ${props.className}`} placeholder={props.placeholder} value={props.value} onChange={value => change(value.target.value)}></input>
        </div>
    )
}

InputText.defaultProps = {
    err: false,
    placeholder: "",
    label: "",
    className: "",
    disabled: false
}

export const InputNumber = props => {
    const change = (v) => {
        if (props.onChange != undefined) props.onChange(v);
    }

    return (
        <div className="relative">
            <label className="inputTextLabel">{props.label}</label>
            {props.err ? <span className="inputTextErr">* Este campo es requerido</span> : null}
            <input type="number" disabled={props.disabled} className={props.err ? `inputText form-control error ${props.className}` : `inputText form-control ${props.className}`} placeholder={props.placeholder} value={props.value} onChange={value => change(value.target.value)}></input>
        </div>
    )
}

InputNumber.defaultProps = {
    err: false,
    placeholder: "",
    label: "",
    className: "",
    disabled: false
}

export const InputTextArea = props => {
    const change = (v) => {
        if (props.onChange != undefined) props.onChange(v);
    }

    return (
        <div className="relative">
            <label className="inputTextLabel">{props.label}</label>
            {props.err ? <span className="inputTextErr">* Este campo es requerido</span> : null}
            <textarea disabled={props.disabled} className={props.err ? `inputText form-control error ${props.className}` : `inputText form-control ${props.className}`} placeholder={props.placeholder} onChange={value => change(value.target.value)} rows="10" cols="50">{props.value}</textarea>
        </div>
    )
}

InputTextArea.defaultProps = {
    err: false,
    placeholder: "",
    label: "",
    className: "",
    disabled: false
}

export const InputPassword = props => {
    const change = (v) => {
        if (props.onChange != undefined) props.onChange(v);
    }

    return (
        <div className="relative">
            <label className="inputTextLabel">{props.label}</label>
            {props.err ? <span className="inputTextErr">* Este campo es requerido</span> : null}
            <input type="password" disabled={props.disabled} className={props.err ? `inputText form-control error ${props.className}` : `inputText form-control ${props.className}`} placeholder={props.placeholder} value={props.value} onChange={value => change(value.target.value)}></input>
        </div>
    )
}

InputPassword.defaultProps = {
    err: false,
    placeholder: "",
    label: "",
    className: "",
    disabled: false
}



export const Select = props => {
    const [val, setVal] = useState({ value: 'Región Metropolitana', label: 'Región Metropolitana' })

    useEffect(() => {
        if (props.value == undefined) {
            setVal({ value: '', label: '' })
        } else if (typeof props.value === 'string') {
            setVal({ value: props.value, label: props.value })
        } else if (Object.keys(props.value).length === 0) {
            setVal({ value: '', label: '' })
        } else {
            setVal(props.value)
        }
    }, [props.value])

    const change = (val) => {
        setVal(val);
        if (props.onChange != undefined) props.onChange(val);
    }

    return (
        <div className="relative">
            <label className="SelectLabel">{props.label}</label>
            {props.err ? <span className="inputTextErr">* Este campo es requerido</span> : null}
            <Seleccion maxMenuHeight={135} className={`menu-outer-top Select form-select ${props.className}`} options={props.options} placeholder={props.placeholder} value={val} onChange={v => change(v)} />
        </div>
    )
}

Select.defaultProps = {
    err: false,
    options: [],
    placeholder: "",
    className: "",
    value: { value: '', label: '' }
}

export const SelectLight = props => {
    const [val, setVal] = useState(null)
    useEffect(() => {
        if (props.value == undefined) {
            setVal({ value: '', label: '' })
        } else if (typeof props.value === 'string') {
            setVal({ value: props.value, label: props.value })
        } else if (Object.keys(props.value).length === 0) {
            setVal({ value: '', label: '' })
        } else {
            setVal(props.value)
        }
    }, [props.value])

    const change = (val) => {
        setVal(val);
        if (props.onChange != undefined) props.onChange(val);
    }

    return (
        <div className="relative">
            <Seleccion maxMenuHeight={135} isDisabled={!props.disabled} className={`menu-outer-top SelectLight form-select ${props.className}`} styles={{ margin: 0, background: props.bg }} options={props.options} placeholder={props.placeholder} value={props.value} onChange={v => change(v)} />
        </div>
    )
}

SelectLight.defaultProps = {
    options: [],
    className: "",
    value: { value: '', label: '' },
    disabled: false,
    bg: "#fff",
    value: null
}

export const InputAvatar = props => {

    const [src, setSrc] = useState('');

    useEffect(() => {
        setSrc(props.src);
    }, [props.src])

    const readURL = (file) => {
        return new Promise((res, rej) => {
            const reader = new FileReader();
            reader.onload = e => res(e.target.result);
            reader.onerror = e => rej(e);
            reader.readAsDataURL(file);
        });
    }

    const setAvatar = async (f) => {
        const url = await readURL(f);
        setSrc(url);

        if (props.onChange != undefined) props.onChange(f);
    }

    return (
        <div className={`InputAvatar ${props.className}`}>
            <img src={src} className="InputAvatarImg img-fluid avatar" />
            <div className="file-upload">
                <label className="InputAvatarLabel" htmlFor="userAvatar98">{IconCamera}</label>
                <input id="userAvatar98" type="file" onChange={v => setAvatar(v.target.files[0])} />
            </div>
        </div>
    )
}

InputAvatar.defaultProps = {
    className: ""
}

export const ProgressBar = props => {
    return (
        <div className={`ProgressBar ${props.className}`}>
            <span>{props.value}%</span>
            <div className="progress">
                <div className="progress-bar" role="progressbar" style={{ width: props.value + "%" }} ariaValuenow="25" ariaValuemin="0" ariaValuemax="100"></div>
            </div>
            <p>{props.label}</p>
        </div>
    )
}

ProgressBar.defaultProps = {
    value: 0,
    className: "",
    label: ""
}


export const Ranking = props => {
    const [starts, setStarts] = useState([]);

    useEffect(() => {
        let d = [];
        let i = 0;
        while (i < 5) {
            if (i < props.value) d.push(startActive);
            else d.push(startDisabled)
            i++;
        }

        setStarts(d);
    }, [props.value])


    return (
        <div className={`Ranking ${props.className}`}>
            {starts.map(e => e)}
        </div>
    )
}

Ranking.defaultProps = {
    value: 0,
    className: ""
}


export const InputCalendar = props => {

    const change = date => {
        if (props.onChange != undefined) props.onChange(date);
    }

    const renderCustomInput = ({ ref }) => (
        <input
            readOnly
            ref={ref} // necessary
            placeholder={props.placeholder}
            value={props.value ? `${props.value.day}-${props.value.month}-${props.value.year}` : ''}
            style={{ margin: 0 }}
            className={`InputCalendar form-control ${props.className}`}
        />
    )

    return (
        <div className="relative">
            <label className="InputCalendarLabel">{props.label}</label>
            {props.err ? <span className="inputTextErr">* Este campo es requerido</span> : null}
            <DatePicker
                value={props.value}
                onChange={change}
                inputPlaceholder={props.placeholder}
                shouldHighlightWeekends
                calendarClassName="responsive-calendar"
                locale={localeEs}
                renderInput={renderCustomInput}
            />
        </div>
    )
}


InputCalendar.defaultProps = {
    err: false,
    placeholder: "DD-MM-YYYY",
    value: undefined,
    label: "",
    className: ""
}


export const InputPasswordModal = props => {

    const [actual, setActual] = useState("");
    const [nuevo, setNuevo] = useState("");
    const [confirm, setConfirm] = useState("");
    const [err, setErr] = useState(false);
    const [errMessage, setErrMessage] = useState('');

    const change = val => {
        if (actual.length == 0) {
            setErr(true);
            setErrMessage('Contraseña actual no puede estar vacio');
            return false;
        }

        if (nuevo.length == 0 || confirm.length == 0 || nuevo !== confirm) {
            setErr(true);
            setErrMessage('Las contraseñas no coinciden');
            return false;
        }

        if (!schema.validate(nuevo)) {
            setErr(true);
            setErrMessage('La contraseña debe contener minimo 8 digitos entre mayusculas, minusculas y números');
            return false;
        }

        setErr(false);
        if (props.onChange != undefined) props.onChange(actual, nuevo);
    }

    return (
        <div className="InputPasswordContainer">
            <label className="inputPasswordLabel">{props.label}</label>
            <input disabled={true} type="password" value={props.value} placeholder={props.placeholder} className="InputPassword form-control" value={props.value} onChange={value => change(value.target.value)}></input>
            <span className="InputPasswordOpenModal" data-toggle="modal" data-target="#changePasswordModal"> <img src={EditIcon} alt="edit" /></span>
            <Modal id="changePasswordModal" title="Cambiar contraseña" accept={true} cancel={true} textCancel="Cancelar" textAccept="Guardar" onClick={change}>
                <br />
                <div className="row mb-40">
                    <div className="col-sm-12">
                        <input type="password" className="InputPassword form-control" placeholder="Contraseña actual" onChange={value => setActual(value.target.value)}></input>
                    </div>
                    <div className="col-sm-12">
                        <input type="password" className="InputPassword form-control" placeholder="Nueva contraseña" onChange={value => setNuevo(value.target.value)}></input>
                    </div>
                    <div className="col-sm-12">
                        <input type="password" className="InputPassword form-control" placeholder="Repetir contraseña" onChange={value => setConfirm(value.target.value)}></input>
                    </div>
                    <div className="text-center w-100">
                        {err ? <span className="error-label">{errMessage}</span> : null}
                        {props.invalidCurrentPass ? <span className="error-label">Password actual incorrecto</span> : null}
                    </div>
                </div>
            </Modal>
        </div>
    )
}


InputPasswordModal.defaultProps = {
    err: false,
    placeholder: "",
    label: "",
    className: "",
    value: "",
    invalidCurrentPass: false
}




export const InputPhone = (props) => {
    const change = (val) => {
        if (props.onChange != undefined) props.onChange(val);
    }
    return (
        <div className="relative">
            <label className="inputPhoneLabel">{props.label}</label>
            {props.err ? <span className="inputTextErr">* Este campo es requerido</span> : null}
            <PhoneInput
                country={'cl'}
                value={props.value}
                onChange={change}
            />
        </div>
    )
}

InputPhone.defaultProps = {
    err: false
}



export const DocuFile = props => {
    const [id, setId] = useState(Math.random().toString(36).slice(-8));
    const [p, setP] = useState(0);

    const change = (file) => {
        setP(100);
        if (props.onChange != undefined) props.onChange(file);
    }

    const remove = () => {
        setP(0);
        if (props.onRemove != undefined) props.onRemove();
    }

    useEffect(() => {
        if (props.src != undefined) setP(100);
        else setP(0);
    }, [props.src])

    return (
        <div className="user-file-item d-flex align-items-center">
            <div className="user-file-item-content">
                <svg width="28" height="21" viewBox="0 0 28 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M24.1059 0.270878H3.70596C2.18219 0.268389 0.944876 1.50166 0.942387 3.02548C0.942179 3.14947 0.950321 3.27331 0.966811 3.39616C1.00602 3.69911 1.2834 3.91292 1.5864 3.87371C1.66019 3.86417 1.73129 3.83985 1.79544 3.80215C2.03948 3.66016 2.31738 3.58679 2.59969 3.58974H7.60013C8.31088 3.59171 8.94235 4.04375 9.17327 4.71593L9.26729 5.01903C9.64792 6.14573 10.7033 6.90534 11.8925 6.90855H25.2122C25.5023 6.90881 25.7872 6.9851 26.0386 7.12983C26.1235 7.17935 26.2201 7.20528 26.3185 7.20507C26.624 7.20507 26.8716 6.9574 26.8716 6.65191V3.03658C26.8716 1.50913 25.6334 0.270878 24.1059 0.270878Z" fill="#FDAD34" />
                    <path d="M26.0673 5.11414C25.6466 4.86891 25.1682 4.73989 24.6811 4.7402H11.8925C11.1817 4.73823 10.5503 4.28619 10.3193 3.61402L10.2253 3.31091C9.84469 2.18421 8.78934 1.4246 7.6001 1.42139H3.6617C3.18643 1.41677 2.71873 1.54019 2.3076 1.77874C1.43573 2.26666 0.895737 3.18791 0.895996 4.18709V17.4625C0.895996 18.99 2.13424 20.2282 3.6617 20.2282H24.6811C26.2086 20.2282 27.4468 18.99 27.4468 17.4625V7.50595C27.4503 6.51869 26.9236 5.60559 26.0673 5.11414Z" fill="#FF7C03" />
                </svg>
            </div>
            <div className="user-file-item-content w-100">
                <p>{props.name}</p>
                <div className="progress">
                    <div className="progress-bar" role="progressbar" style={{ width: p + "%" }} ariaValuenow="100" ariaValuemin="0" ariaValuemax="100"></div>
                </div>
            </div>
            <div className="user-file-item-content">
                {p > 0 ? <label data-toggle="modal" data-target={"#modal-" + id}>
                    <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M0.604248 10.276C0.604248 4.66358 5.16969 0.0981445 10.7821 0.0981445C16.3945 0.0981445 20.9599 4.66438 20.9599 10.276C20.9599 15.8876 16.3945 20.4538 10.7821 20.4538C5.16969 20.4538 0.604248 15.8884 0.604248 10.276ZM2.18098 10.276C2.18098 15.0188 6.04005 18.8771 10.7821 18.8771C15.5249 18.8771 19.3832 15.0188 19.3832 10.276C19.3832 5.53315 15.5241 1.67488 10.7821 1.67488C6.04005 1.67488 2.18098 5.53315 2.18098 10.276ZM11.8971 10.205L14.127 12.4349C14.4352 12.7432 14.4347 13.2421 14.1269 13.5498C13.8192 13.8575 13.3203 13.8581 13.012 13.5498L10.7822 11.32L8.55233 13.5498C8.24461 13.8575 7.74567 13.8581 7.43739 13.5498C7.12911 13.2415 7.12967 12.7426 7.43739 12.4349L9.66722 10.205L7.43739 7.97521C7.12911 7.66693 7.12967 7.16798 7.43739 6.86026C7.74511 6.55254 8.24405 6.55198 8.55234 6.86026L10.7822 9.0901L13.012 6.86026C13.3197 6.55254 13.8187 6.55198 14.1269 6.86026C14.4352 7.16855 14.4347 7.66749 14.1269 7.97521L11.8971 10.205Z" fill="#B5B8C9" />
                    </svg>
                </label> : <label htmlFor={"uploadfile-" + id}>
                    <input type="file" id={"uploadfile-" + id} onChange={v => { v.persist(); change(v.target.files[0]) }} style={{ display: 'none' }} />
                    <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M0.604248 10.2605C0.604248 4.64805 5.16969 0.0826111 10.7821 0.0826111C16.3945 0.0826111 20.9599 4.64885 20.9599 10.2605C20.9599 15.8721 16.3945 20.4383 10.7821 20.4383C5.16969 20.4383 0.604248 15.8729 0.604248 10.2605ZM2.18098 10.2605C2.18098 15.0033 6.04005 18.8616 10.7821 18.8616C15.5249 18.8616 19.3832 15.0033 19.3832 10.2605C19.3832 5.51762 15.5241 1.65934 10.7821 1.65934C6.04005 1.65934 2.18098 5.51762 2.18098 10.2605ZM11.5705 9.40112H14.7239C15.1599 9.40112 15.5123 9.75433 15.5123 10.1895C15.5123 10.6247 15.1599 10.9779 14.7239 10.9779H11.5705V14.1314C11.5705 14.5665 11.2181 14.9197 10.7821 14.9197C10.3461 14.9197 9.99371 14.5665 9.99371 14.1314V10.9779H6.84024C6.40427 10.9779 6.05186 10.6247 6.05186 10.1895C6.05186 9.75433 6.40427 9.40112 6.84024 9.40112H9.99371V6.24766C9.99371 5.81248 10.3461 5.45928 10.7821 5.45928C11.2181 5.45928 11.5705 5.81248 11.5705 6.24766V9.40112Z" fill="black" />
                    </svg>
                </label>}
            </div>
            <DeleteFileModal id={"modal-" + id} title="¿Desea eliminar este elemento?" accept={true} cancel={true} textCancel="Cancelar" textAccept="Borrar" onClick={remove}>
                <br />
                Esta acción no es reversible
                <br />
                <br />
            </DeleteFileModal>
        </div>
    )
}

DocuFile.defaultProps = {
    name: "",
    src: undefined
}

export const ServicePill = props => {
    return (<div className="servicePill" style={{ background: props.color }}>{props.name}</div>)
}

ServicePill.defaultProps = {
    name: "",
    color: "#2178FC"
}

export const MultiSelect = props => {
    const [message, setMessage] = useState("");
    const [err, setErr] = useState(false);
    const [select, setSelect] = useState([]);
    const change = (v) => {

        if (v.length <= props.max) {
            setErr(false);
            if (props.onChange != undefined) props.onChange(v);
        } else {
            setMessage('Solo puedes seleccionar hasta 3 opciones')
            setErr(true);
        }
    }

    const animatedComponents = makeAnimated();

    const colourStyles = {
        control: styles => ({ ...styles, backgroundColor: 'white' }),
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            const color = chroma(data.color);
            return {
                ...styles,
                backgroundColor: isDisabled
                    ? null
                    : isSelected
                        ? data.color
                        : isFocused
                            ? color.alpha(0.1).css()
                            : null,
                color: isDisabled
                    ? '#ccc'
                    : isSelected
                        ? chroma.contrast(color, 'white') > 2
                            ? 'white'
                            : 'black'
                        : data.color,
                cursor: isDisabled ? 'not-allowed' : 'default',

                ':active': {
                    ...styles[':active'],
                    backgroundColor:
                        !isDisabled && (isSelected ? data.color : color.alpha(0.3).css()),
                },
            };
        },
        multiValue: (styles, { data }) => {
            const color = chroma(data.color);
            return {
                ...styles,
                backgroundColor: color.alpha(0.1).css(),
            };
        },
        multiValueLabel: (styles, { data }) => ({
            ...styles,
            color: data.color,
        }),
        multiValueRemove: (styles, { data }) => ({
            ...styles,
            color: data.color,
            ':hover': {
                backgroundColor: data.color,
                color: 'white',
            },
        }),
    };

    useEffect(() => {
        setSelect(props.selectedOptions.map(e => {
            e['value'] = e.name;
            e['label'] = e.name;
            return e;
        }))

    }, [props.selectedOptions])

    return (
        <Fragment>
            <div className="text-left">
                <Seleccion
                    maxMenuHeight={135}
                
                    // defaultValue={select} 
                    value={props.selectedOptions}
                    isMulti
                    options={props.options}
                    onChange={change}
                    styles={colourStyles}
                    components={animatedComponents}
                    {...props}
                />
            </div>
            <br />
            {err ? <span>{message}</span> : null}
        </Fragment>
    )
}

MultiSelect.defaultProps = {
    options: [],
    selectedOptions: [],
    max: 3
}


export const ModalAgreggateService = props => {
    const [id, setId] = useState(Math.random().toString(36).slice(-8));
    const [selected, setSelected] = useState([])
    const [search, setSearch] = useState('')

    const changeSelect = v => {
        setSelected(v);
    }

    const accepts = () => {
        if (selected.length < 4) {
            closeOneModal("addUserService" + id);
            if (props.onClick != undefined) props.onClick(selected);
        }
    }

    useEffect(() => {
        setSelected(props.values);
    }, [props.values])

    return (
        <Fragment>
            <label style={{ background: props.background, color: props.color }} className="addServicesBtn" data-toggle="modal" data-target={"#addUserService" + id}>Agregar</label>
            <Modal size={'md'} id={"addUserService" + id} title="Agregar un nuevo servicio" accept={true} cancel={true} textCancel="Cancelar" textAccept="Guardar" onClick={accepts}>
                <span>Selecciona hasta {props.max} opciones</span>
                <Row className="mb-40">
                    <Col className="pt-3">
                        <InputText placeholder="" label="Buscar..." value={search} onChange={value => setSearch(value)} />
                        <SelectMulti options={props.options} filter={search} selectedOptions={selected} onChange={changeSelect} />
                    </Col>
                </Row>
            </Modal>
        </Fragment>
    )
}

ModalAgreggateService.defaultProps = {
    options: [],
    max: 3,
    values: [],
    background: "transparent",
    color: "#5A6170"
}



export const SelectMulti = props => {
    const c = useRef();
    const [message, setMessage] = useState("");
    const [err, setErr] = useState(false);
    const [select, setSelect] = useState([]);

    const re = () => {
        const all = Array.from(c.current.querySelectorAll('input'));
        const select = all.filter(e => e.checked);
        let i = 0;
        const result = [];
        while (i < select.length) {
            result.push(props.options.find(e => e._id === select[i]['id']));
            i++
        }

        if (result.length <= props.max) {
            setErr(false);
            if (props.onChange != undefined) props.onChange(result);
        } else {
            setMessage('Solo puedes seleccionar hasta 3 opciones')
            setErr(true);
        }
    }

    const valueChecked = (id) => {
        let i = 0;
        let v = false;
        if (props.selectedOptions.find(e => e._id === id) != undefined) {
            return true;
        } else {
            return false;
        }
    }

    return (
        <Fragment>
            <div className={`${props.className} ${props.center ? "text-center" : ""}`} ref={c} style={{ height: 130, overflowY: 'auto' }}>
                {props.options.map(e => {
                    if (!(e.name).toLowerCase().includes(props.filter.toLowerCase())) return false;
                    return (
                        <Fragment>
                            <input className="SelectMultiCheck" type="checkbox" checked={valueChecked(e._id)} id={e._id} onChange={v => { re() }} />
                            <label className={`SelectMultiLabel ${props.itemClassName}`} htmlFor={e._id}>{e.label}</label>
                        </Fragment>
                    )
                })}
            </div>

            {err ? <span>{message}</span> : null}
        </Fragment>
    )
}

SelectMulti.defaultProps = {
    options: [],
    selectedOptions: [],
    max: 3,
    className: "",
    center: true,
    itemClassName: ""
}