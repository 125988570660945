import React from 'react'
import './style.css'


const Toggle = (props) => {

    const id = Math.random().toString(36).slice(-8);
    const change = (v) => {
        if(props.onChange != undefined) props.onChange(v.target.checked);
    }

    return (
        <div className="mytoggle">
            <input disabled={props.disabled} id={id} type="checkbox" checked={props.value} onChange={change}/>
            <label htmlFor={id}>
                <span class="buttonbackground">
                    <span class="buttonslider"></span>
                </span>
            </label>
        </div>
    )
}

Toggle.defaultProps = {
    disabled: false
}

export default Toggle;