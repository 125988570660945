import React, { useState, useEffect } from "react";
import axios from 'axios'
import Main from "../../components/Main";
import Header from '../../components/Header';
import { Card } from '../../components/card';
import { connect } from '../../store';
import { Bar, HorizontalBar } from 'react-chartjs-2';
import DatePicker, { utils } from 'react-modern-calendar-datepicker';
import { localeEs } from '../MiPanel/locale'
import 'react-modern-calendar-datepicker/lib/DatePicker.css';
import moment from "moment";


const Operaciones = (props) => {
    const [data, setData] = useState({ labels: [], datasets: [] })
    const { years: year, months: month, date: day } = moment().subtract({ days: 365 }).toObject();
    const [dateStart, setDateStart] = useState({ from: { year, month: month + 1, day }, to: utils().getToday() })

    const [ocupacion, setOcupacion] = useState([]);
    const [rechazo, setRechazo] = useState([]);
    const [rechazoExp, setRechazoExp] = useState([]);
    const [evaluacion, setEvaluacion] = useState([]);

    const [dateStartOcupacion, setDateStartOcupacion] = useState({ from: { year, month: month + 1, day }, to: utils().getToday() })
    const [dateStartRechazo, setDateStartRechazo] = useState({ from: { year, month: month + 1, day }, to: utils().getToday() })
    const [dateStartRechazoExp, setDateStartRechazoExp] = useState({ from: { year, month: month + 1, day }, to: utils().getToday() })
    const [dateStartEvaluacion, setDateStartEvaluacion] = useState({ from: { year, month: month + 1, day }, to: utils().getToday() })

    

    const parseDataSet = (data = [], cb = undefined, barPercentage = 0.5, color = colors, border = 2, label = "") => {
        if (cb != undefined) cb({
            labels: data.map(e => e.label),
            datasets: [{
                barPercentage: barPercentage,
                label: label,
                data: data.map(e => e.total),
                backgroundColor: data.map((e, i) => e.color ? e.color : colors[i]),
                borderColor: data.map((e, i) => e.color ? e.color : colors[i]),
                borderWidth: border,
                borderRadius: 10,
            }]
        })
    }

    const colors = [
        'rgba(255, 27, 27, 1)',
        'rgba(255, 160, 0, 1)',
        'rgba(253, 197, 52, 1)',
        'rgba(62, 178, 67, 1)',
        'rgba(73, 195, 157, 1)',
        'rgba(110, 161, 248, 1)',
        'rgba(70, 102, 222, 1)'
    ];

    const [options, setOptions] = useState({
        responsive: true,
        maintainAspectRatio: false,
        legend: { display: false },
        tooltips: {
            mode: 'index',
            intersect: false,
            callbacks: {
                label: function (t, d) {
                    return new Intl.NumberFormat("es-CL", { style: "currency", currency: "CLP" }).format(t.yLabel)
                },
                title: function (t, d) {
                    return t[0].label;
                }
            }
        },

        scales: {
            yAxes: [{
                scaleLabel: {
                    display: false,
                    labelString: "TOTALES",
                },
                ticks: {
                    beginAtZero: true,
                    min: 0,
                    callback: function (value, index, values) {
                        return new Intl.NumberFormat("es-CL", { style: "currency", currency: "CLP" }).format(value)
                    }
                }
            }],
            xAxes: [{
                scaleLabel: {
                    display: false,
                    labelString: "OCUPACIÓN DE LA AGENDA",
                },
                ticks: {
                    min: 0,
                }
            }]
        }
    })

    const getProjectGlobal = () => {
        axios.post('/api/sales/global/project', dateStart)
            .then(res => {
                parseDataSet(res.data.data, data => setData(data))
            })
            .catch(err => console.log(err));
    }

    const getRankingRechazo = (date) => {
        axios.post('/api/operaciones/rechazo', date ? date : dateStartRechazo)
            .then(res => {
                console.log(res.data);
                parseDataSet(res.data.data, data => setRechazo(data))
            })
            .catch(err => console.log(err));
    }

    const getRankingRechazoExperto = (date) => {
        axios.post('/api/operaciones/rechazo/expert', date ? date : dateStartRechazoExp)
            .then(res => {
                console.log(res.data);
                parseDataSet(res.data.data, data => setRechazoExp(data))
            })
            .catch(err => console.log(err));
    }

    const getRankingOcupacionAgenda = (date) => {
        axios.post('/api/operaciones/ocupacion/agenda', date ? date : dateStartOcupacion)
            .then(res => {
                console.log(res.data);
                parseDataSet(res.data.data, data => setOcupacion(data))
            })
            .catch(err => console.log(err));
    }

    const getRankingCalificacion = (date) => {
        axios.post('/api/operaciones/calificacion', date ? date : dateStartEvaluacion)
            .then(res => {
                console.log(res.data);
                parseDataSet(res.data.data, data => setEvaluacion(data))
            })
            .catch(err => console.log(err));
    }

    useEffect(() => {
        getProjectGlobal()
        getRankingRechazoExperto()
        getRankingRechazo()
        getRankingOcupacionAgenda()
        getRankingCalificacion()
    }, [])

    return (
        <Main>
            <div className="row">
                <div className="col-12">
                    <Header title="Panel de operaciones" />
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <Card title="Gráfico proyección de ventas" className="p-5">
                        <Bar
                            data={data}
                            width={200}
                            height={300}
                            options={options}
                        />
                    </Card>
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <Card title="Ranking de servicos rechazados (top 10)" className="p-2" toolbar={<DatePicker
                        value={dateStartRechazo}
                        onChange={(s) => {
                            setDateStartRechazo(s);
                            if (s.to != null) getRankingRechazo(s);
                        }}
                        inputPlaceholder="Selecciona un rango de fecha"
                        shouldHighlightWeekends
                        calendarClassName="responsive-calendar"
                        maximumDate={utils().getToday()}
                        locale={localeEs}
                    />}>
                        <HorizontalBar data={rechazo} options={{
                            responsive: true,
                            maintainAspectRatio: true,
                            legend: { display: false },
                            scales: {
                                xAxes: [{
                                    scaleLabel: { display: false },
                                    ticks: {
                                        suggestedMax: 100,
                                        min: 0,
                                        callback: (value, index, values) => value
                                    }
                                }],
                                yAxes: [{
                                    scaleLabel: { display: false }, ticks: {
                                        min: 0, callback: (value, index, values) => {
                                            let str = value.slice(0, 30);
                                            return str + '...';
                                        }
                                    }
                                }],
                            },
                            tooltips: {
                                mode: 'index',
                                intersect: false,
                                callbacks: {
                                    label: function (t, d) {
                                        return t.xLabel;
                                    },
                                    title: function (t, d) {
                                        return t[0].label;
                                    }
                                }
                            }
                        }} />
                    </Card>
                </div>
                <div className="col-12">
                    <Card title="Ranking de proveedores con mas rechazos (top 10)" className="p-2" toolbar={<DatePicker
                        value={dateStartRechazoExp}
                        onChange={(s) => {
                            setDateStartRechazoExp(s);
                            if (s.to != null) getRankingRechazoExperto(s);
                        }}
                        inputPlaceholder="Selecciona un rango de fecha"
                        shouldHighlightWeekends
                        calendarClassName="responsive-calendar"
                        maximumDate={utils().getToday()}
                        locale={localeEs}
                    />}>
                        <HorizontalBar data={rechazoExp} options={{
                            responsive: true,
                            maintainAspectRatio: true,
                            legend: { display: false },
                            scales: {
                                xAxes: [{
                                    scaleLabel: { display: false },
                                    ticks: {
                                        // beginAtZero: true,
                                        suggestedMax: 100,
                                        min: 0,
                                        callback: (value, index, values) => value
                                    }
                                }],
                                yAxes: [{ scaleLabel: { display: false }, ticks: { min: 0 } }]
                            },
                            tooltips: {
                                mode: 'index',
                                intersect: false,
                                callbacks: {
                                    label: function (t, d) {
                                        return t.xLabel
                                    },
                                    title: function (t, d) {
                                        return t[0].label;
                                    }
                                }
                            }
                        }} />
                    </Card>
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <Card title="Ranking de ocupación proveedores (top 10)" className="p-2" toolbar={<DatePicker
                        value={dateStartOcupacion}
                        onChange={(s) => {
                            setDateStartOcupacion(s);
                            if (s.to != null) getRankingOcupacionAgenda(s);
                        }}
                        inputPlaceholder="Selecciona un rango de fecha"
                        shouldHighlightWeekends
                        calendarClassName="responsive-calendar"
                        maximumDate={utils().getToday()}
                        locale={localeEs}
                    />}>
                        <HorizontalBar data={ocupacion} options={{
                            responsive: true,
                            maintainAspectRatio: true,
                            legend: { display: false },
                            scales: {
                                xAxes: [{
                                    scaleLabel: { display: false },
                                    ticks: {
                                        // beginAtZero: true,
                                        suggestedMax: 100,
                                        min: 0,
                                        callback: (value, index, values) => value + ' Hrs'
                                    }
                                }],
                                yAxes: [{ scaleLabel: { display: false }, ticks: { min: 0 } }]
                            },
                            tooltips: {
                                mode: 'index',
                                intersect: false,
                                callbacks: {
                                    label: function (t, d) {
                                        return t.xLabel + ' Hrs'
                                    },
                                    title: function (t, d) {
                                        return t[0].label;
                                    }
                                }
                            }
                        }} />
                    </Card>
                </div>
                <div className="col-12">
                    <Card title="Ranking de calificacion de proveedores (top 10)" className="p-2" toolbar={<DatePicker
                        value={dateStartEvaluacion}
                        onChange={(s) => {
                            setDateStartEvaluacion(s);
                            if (s.to != null) getRankingCalificacion(s);
                        }}
                        inputPlaceholder="Selecciona un rango de fecha"
                        shouldHighlightWeekends
                        calendarClassName="responsive-calendar"
                        maximumDate={utils().getToday()}
                        locale={localeEs}
                    />}>
                        <HorizontalBar data={evaluacion} options={{
                            responsive: true,
                            maintainAspectRatio: true,
                            legend: { display: false },
                            scales: {
                                xAxes: [{
                                    scaleLabel: { display: false },
                                    ticks: {
                                        suggestedMax: 100,
                                        min: 1,
                                    }
                                }],
                                yAxes: [{
                                    scaleLabel: { display: false }, ticks: {
                                        min: 0, callback: (value, index, values) => {
                                            let str = value.slice(0, 30);
                                            return str + '...';
                                        }
                                    }
                                }]
                            },
                            tooltips: {
                                mode: 'index',
                                intersect: false,
                                callbacks: {
                                    label: function (t, d) {
                                        return t.xLabel
                                    },
                                    title: function (t, d) {
                                        return t[0].label;
                                    }
                                }
                            }
                        }} />
                    </Card>
                </div>
            </div>
        </Main>
    )
}

export default connect(Operaciones);