import React, { useEffect, useState, useRef } from "react";
import PureModal from 'react-pure-modal';
import 'react-pure-modal/dist/react-pure-modal.min.css';
import Spinner from '../spinner'
import './style.css'

const closeAllModals = () => {
    const modals = document.getElementsByClassName('modal');
    for (let i = 0; i < modals.length; i++) {
        modals[i].classList.remove('show');
        modals[i].setAttribute('aria-hidden', 'true');
        modals[i].setAttribute('style', 'display: none');
    }
    const modalsBackdrops = document.getElementsByClassName('modal-backdrop');
    for (let i = 0; i < modalsBackdrops.length; i++) {
        document.body.removeChild(modalsBackdrops[i]);
    }
}

const closeOneModal = (modalId) => {
    const modal = document.getElementById(modalId);
    console.log("::>>", modalId, modal);
    modal.classList.remove('show');
    modal.setAttribute('aria-hidden', 'true');
    modal.setAttribute('style', 'display: none');
    const modalBackdrops = document.getElementsByClassName('modal-backdrop');
    document.body.removeChild(modalBackdrops[0]);
}

export const Modal = (props) => {

    const click = () => {
        if (props.onClick != undefined) props.onClick();
        if (props.autoClose) closeOneModal(props.id)
    }

    return (
        <div className="modal fade tt-modal" id={props.id} tabIndex="-1" role="dialog" aria-labelledby={props.id + "Label"} aria-hidden="true">
            <div className={`modal-dialog modal-dialog-centered ${props.lg ? "modal-lg" : ''} ${props.size}`} role="document">
                <div className="modal-content">
                    <div className="modal-body">
                        <h4>{props.title}</h4>
                        {props.subtitle != null ? <p className="subtitle">{props.subtitle}</p> : null}
                        {props.children}
                    </div>
                    <div className="modal-footer">
                        {props.cancel ? <button type="button" className="btn btn-white" data-dismiss="modal">{props.textCancel}</button> : null}
                        {props.accept ? <button type="button" className="btn btn-secondary" onClick={click}>{props.textAccept} {props.load ? <Spinner /> : null}</button> : null}
                    </div>
                </div>
            </div>
        </div>
    )
}

Modal.defaultProps = {
    subtitle: null,
    close: false,
    size: 'xs',
}

export const DeleteFileModal = (props) => {
    return (
        <div className="modal fade tt-modal" id={props.id} tabIndex="-1" role="dialog" aria-labelledby={props.id + "Label"} aria-hidden="true">
            <div className={`modal-dialog modal-dialog-centered ${props.lg ? "modal-lg" : ''}`} role="document">
                <div className="modal-content">
                    <div className="modal-body">
                        <h4>{props.title}</h4>
                        <span>{props.children}</span>
                    </div>
                    <div className="modal-footer">
                        {props.cancel ? <button type="button" className="btn btn-white" data-dismiss="modal">{props.textCancel}</button> : null}
                        {props.accept ? <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={props.onClick}>{props.textAccept}</button> : null}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Modal;



export const ModalSimple = (props) => {
    return (
        <PureModal
            header={props.title}
            replace={props.replace}
            closeButtonPosition="header"
            footer={
                <div className="text-center">
                    {props.cancel ?
                        <button type="button" className="btn btn-white m-3" onClick={() => {
                            if (props.onClose != undefined) props.onClose(false);
                            if (props.onCancel != undefined) props.onCancel(false);
                        }}>{props.textCancel}</button>
                        : null}

                    {props.accept ? <button type="button" className="btn btn-secondary m-3" onClick={() => {
                        if (props.onClick != undefined) props.onClick();
                        if (props.autoClose) {
                            if (props.onClose != undefined) props.onClose(false);
                        }
                    }}>{props.textAccept}</button> : null}
                </div>
            }
            width={props.width}
            portal={props.portal}
            isOpen={props.open}
            closeButton="close"
            onClose={() => {
                if (props.onClose != undefined) props.onClose(false);
            }}
        >
            <div style={{ padding: props.padding }}>{props.children}</div>
        </PureModal>
    )
}

ModalSimple.defaultProps = {
    title: "",
    open: false,
    autoClose: true,
    close: false,
    textCancel: "Cancelar",
    textAccept: "Aceptar",
    size: 'xs',
    width: '500px',
    height: "auto",
    replace: false,
    portal: true,
    padding: 0
}