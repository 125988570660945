export const UPDATE_VERSION = 'UPDATE_VERSION';
export const TOGGLE_SIDEBAR = 'TOGGLE_SIDEBAR';
export const SET_HEAD = 'SET_HEAD';
export const TOOLS = 'TOOLS';
export const GET_PROJECTS = 'SET_PROJECTS';
export const TOGGLE_SIDESHEETS = 'TOGGLE_SIDESHEETS';
export const SET_VIEWPORT = 'SET_VIEWPORT';
export const ACTIVE_MENU = 'ACTIVE_MENU';
export const SET_GOBACK = 'SET_GOBACK';
export const PUSH_NOTIFIACTION = 'PUSH_NOTIFIACTION';
export const REMOVE_NOTIFIACTION = 'REMOVE_NOTIFIACTION';
export const SET_FORM_TYPE = 'SET_FORM_TYPE';
export const USER_ID_ACTIVATE = 'USER_ID_ACTIVATE';
export const SHEDULE_EVENTS = 'SHEDULE_EVENTS';
export const SHEDULE_PENDINGS = 'SHEDULE_PENDINGS';

export const CAT_SERVICES = 'CAT_SERVICES';
export const GROUPS_SERVICES = 'GROUPS_SERVICES';
export const GET_EXPERTS = 'GET_EXPERTS';
export const GET_CLIENTS = 'GET_CLIENTS';
export const MASTER_SERVICES = 'MASTER_SERVICES';
