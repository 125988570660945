import React from 'react'
import './style.css'

const Avatar = (props) => {
    return (<img src={props.src} className="img-fluid avatar" />)
}

Avatar.defaultProps = {
    src: ''
}

export default Avatar;