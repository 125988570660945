import React, { useState, useEffect } from "react";
// Componentes
import axios from 'axios'
import Main from "../../components/Main";
import Header from '../../components/Header';
import { Card } from '../../components/card';
import Table from '../../components/table';
import { connect } from '../../store';
import { BtnDelete, BtnEdit, BtnSee } from '../../components/buttons'
import { ModalSimple } from '../../components/modal'
import { UnlockAccess, grantPermission } from '../../lib/permission'
import { Row, Col } from '../../components/grid'
import Toggle from '../../components/toggle'
import './style.css';


const Clients = (props) => {
  const [data, setData] = useState([])
  const [current, setCurrent] = useState({});
  const [current2, setCurrent2] = useState({});
  const [edit, setEdit] = useState(null);
  const [see, setSee] = useState(false);
  const [seeDelete, setSeeDelete] = useState(false);
  const [columns] = useState([
    { key: 'rut', text: 'Rut' },
    { key: 'name', text: 'Nombre' },
    { key: 'lastname', text: 'Apellido' },
    { key: 'email', text: 'Email' },
    { key: 'phone', text: 'Teléfono' },
    {
      key: 'controls', text: 'Acciones', elem: (u) => {
        return <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
          <BtnDelete onClick={() => { setCurrent2(u); setSeeDelete(true); }} />
          <BtnSee onClick={() => { setCurrent(u); setSee(true); }} />
        </div>
      }
    }
  ])

  useEffect(() => {
    getClientes();
  }, [])

  const getClientes = () => {
    axios.get('/api/clients')
      .then(res => setData(res.data.data))
      .catch(err => console.log(err))
  }

  const deleteUser = () => {
    axios.delete('/api/clients/' + current2._id)
      .then(res => {
        props.notify({ type: "success", title: "Eliminar cliente", text: "Cliente eliminado" });
        getClientes();
        setSeeDelete(false);
      })
      .catch(err => console.log(err))
  }

  return (
    <Main>
      <Row>
        <Col>
          <Header title="Usuarios" />
        </Col>
      </Row>

      <Row>
        <Col>
          <Card title="Usuarios registrados">
            <Table columns={columns} data={data} background="#FF8B03" paginationClass="table-buttons" pagination={false} />
          </Card>
        </Col>
      </Row>
      <ModalSimple width="700px" padding={20} open={see} onClose={setSee} textCancel="Modificar" accept={true} textAccept="Aceptar" >
        <Row>
          <Col>
            <h4 className="mb-4">Datos del cliente</h4>
          </Col>
          <Col>
            <TableEdit current={current} onChange={(d) => setEdit(d)} />
          </Col>
        </Row>
      </ModalSimple>

      <ModalSimple width="700px" padding={20} open={seeDelete} onClose={setSeeDelete} textCancel="Cancelar" cancel={true} accept={true} textAccept="Eliminar" onClick={deleteUser}>
        <Row>
          <Col className="text-center">
            <h4 className="mb-4">¿Desea elimminar a {current2 && current2.name + " " + current2.lastname}?</h4>
          </Col>
        </Row>
      </ModalSimple>

    </Main>
  )
}

export default connect(Clients);



const TableEdit = (props) => {

  const [data, setData] = useState({})

  useEffect(() => {
    setData(props.current);
  }, [props.current])

  const setDataKey = (key, value) => {
    let current = Object.assign({}, data);
    current[key] = value;
    setData(current);

    if (props.onChange != undefined) props.onChange(current);
  }

  const enabledOrDisabledClient = (id, opt = true) => {
    axios.post(`/api/cliente/enabled/${id}`, { enabled: opt })
      .then(res => {
        props.notify({ type: "success", title: "Habiliatr/Deshabilitar cliente", text: opt ? "Habilitado con exito" : "Deshabilitado con exito" });
        if (props.onChange != undefined) props.onChange(data);
      })
      .catch(err => props.notify({ type: "error", title: "Habiliatr/Deshabilitar cliente", text: "Ha ocurrido un error, favor intente mas tarde" }))
  }

  return (
    <table className="table table-vertical-center ">
      <tbody>
        <tr>
          <td><strong>Rut:</strong></td>
          <td><input disabled={!grantPermission(['superadmin', 'admin', 'full'])} style={{ margin: 0, padding: 0 }} type="text" className="form-control" value={data && data.rut} onChange={v => setDataKey('rut', v.target.value)} /></td>
        </tr>
        <tr>
          <td><strong>Nombre:</strong></td>
          <td><input disabled={!grantPermission(['superadmin', 'admin', 'full'])} style={{ margin: 0, padding: 0 }} type="text" className="form-control" value={data && data.name} onChange={v => setDataKey('name', v.target.value)} /></td>
        </tr>
        <tr>
          <td><strong>Apellido:</strong></td>
          <td><input disabled={!grantPermission(['superadmin', 'admin', 'full'])} style={{ margin: 0, padding: 0 }} type="text" className="form-control" value={data && data.lastname} onChange={v => setDataKey('lastname', v.target.value)} /></td>
        </tr>
        <tr>
          <td><strong>Email:</strong></td>
          <td><input disabled={!grantPermission(['superadmin', 'admin', 'full'])} style={{ margin: 0, padding: 0 }} type="text" className="form-control" value={data && data.email} onChange={v => setDataKey('email', v.target.value)} /></td>
        </tr>
        <tr>
          <td><strong>Teléfono:</strong></td>
          <td><input disabled={!grantPermission(['superadmin', 'admin', 'full'])} style={{ margin: 0, padding: 0 }} type="text" className="form-control" value={data && data.phone} onChange={v => setDataKey('phone', v.target.value)} /></td>
        </tr>
        {
          data && data.address && data.address.map((e, i) => {
            return (
              <tr>
                <td className="pv-15"><strong>Dirección {i + 1}:</strong></td>
                <td className="pv-15">{e.address} {e.address_other.length > 0 ? `(complemento: ${e.address_other})` : null}</td>
              </tr>
            )
          })
        }

        <tr>
          <td><strong>Habilitado:</strong></td>
          <td><Toggle disabled={!grantPermission(['superadmin', 'admin', 'full'])} value={data && data.enabled} onChange={v => enabledOrDisabledClient(data._id, v)} /></td>
        </tr>
      </tbody>
    </table>
  )
}