import React, { useState } from 'react';
import './index.css'
import { Row, Col } from '../grid'
import { SecondaryBtn } from '../buttons'

export const Card = (props) => {

    const click = () => {
        if (props.onClick != undefined) props.onClick();
    }

    const click2 = () => {
        if (props.onClick != undefined) props.onClickSecondary();
    }

    return (
        <div className="card-container" style={props.style}>
            <div className="card-title">
                <Row>
                    <Col md={props.button || props.secondButton || props.toolbar ? 8 : 12} style={{ display: 'flex', alignItems: 'center' }}>
                        <span>{props.title}</span>
                    </Col>

                    {props.secondButton ? <Col md={props.button ? 2 : 4} className="text-right" style={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
                        <button className="btn-line" onClick={click2}>{props.textSecond}</button>
                    </Col> : null}

                    {props.button ? <Col md={props.secondButton ? 2 : 4} className="text-right">
                        <SecondaryBtn onClick={click}>{props.textButton}</SecondaryBtn>
                    </Col> : null}

                    {props.toolbar ? <Col md={props.secondButton ? 2 : 4} className="text-right">
                        {props.toolbar}
                    </Col> : null}

                </Row>
            </div>
            <div className={`card-body ${props.className}`}>
                {props.children}
            </div>
        </div>
    )
}

Card.defaultProps = {
    className: '',
    style: {},
    button: false,
    secondButton: false,
    textButton: '',
    textSecond: ''
}

export const FlatCard = (props) => {
    return (
        <div className={`card-container ${props.className}`} style={props.style}>
            <div className="card-body p-4">
                {props.children}
            </div>
        </div>
    )
}

FlatCard.defaultProps = {
    className: '',
    style: {}
}

export const CardWithTitle = (props) => {
    return (
        <div className="card-container" style={props.style}>
            <div className="card-body p-4">
                <div className="text-center">
                    <h4>{props.title}</h4>
                    <p>{props.subtitle}</p>
                </div>
                {props.children}
            </div>
        </div>
    )
}

export const CardWithTitleBlack = (props) => {
    return (
        <div className="card-container bg-black" style={props.style}>
            <div className="card-body p-4">
                <div className="text-center color-white">
                    <h4>{props.title}</h4>
                    <p>{props.subtitle}</p>
                </div>
                {props.children}
            </div>
        </div>
    )
}

Card.defaultProps = {
    children: <div></div>,
    title: "",
    style: {}
}
