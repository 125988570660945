import React, { useState, useEffect } from "react";
// Componentes
import axios from 'axios'
import Main from "../../components/Main";
import Header from '../../components/Header';
import { Card } from '../../components/card';
import { connect } from '../../store';
import { BtnDelete, BtnEdit, BtnSee, SecondaryBtn } from '../../components/buttons'
import Table from '../../components/table';
import { ModalSimple } from '../../components/modal'
import { Row, Col } from '../../components/grid'
import { parseDataSelect } from '../../utils'
import { SelectLight } from '../../components/input'
import fileDownload from 'js-file-download'
import './style.css';
// FloatButton
const SugerenciasServices = (props) => {
  const [see, setSee] = useState(false);
  const [remove, setRemove] = useState(false);
  const [current, setCurrent] = useState(false);
  const [edit, setEdit] = useState(null);
  const [nuevo, setNuevo] = useState(false);
  const [data, setData] = useState([])
  const [columns] = useState([
    { key: 'name', text: 'Nombre' },
    { key: 'lastname', text: 'Apellido' },
    { key: 'rut', text: 'Rut' },
    { key: 'email', text: 'Email' },
    { key: 'phone', text: 'Teléfono' },
    {
      key: 'role', text: 'Rol', elem: (e) => {
        let list = [
          { label: "Administrador", value: "admin" },
          { label: "Full", value: "full" },
          { label: "Basico", value: "basic" }
        ];

        var role = { label: e.role, value: e.role };

        const updateRole = (data) => {
          axios.post(`/api/system/update/role/${e._id}/${data.value}`)
            .then(t => {
              getUsers();
              props.notify({ type: "success", title: "Actualizar rol", text: "Rol actualizado" });
            })
            .catch(err => {
              props.notify({ type: "error", title: "Actualizar rol", text: "No se pudo actualizar el rol" })
            })
        }

        return <SelectLight disabled={true} options={list} onChange={updateRole} value={role} />
      }
    },
    {
      key: 'controls', 'minWidth': 150, text: 'Acciones', data: null, elem: (user) => {
        return <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
          <BtnDelete onClick={() => { setCurrent(user); setRemove(true) }} />
        </div>
      }
    }
  ])

  useEffect(() => {
    getUsers();
  }, [])

  const getUsers = () => {
    axios.get('/api/system/user')
      .then(res => {
        setData(parseDataSelect(res.data.data, '_id', 'name'))
      })
      .catch(err => console.log(err))
  }


  const removeUser = () => {

    axios.delete(`/api/system/user/${current._id}`)
      .then(response => {
        props.notify({ type: "success", title: "Eliminar usuario", text: "Usuario eliminado" });
        getUsers();
      })
      .catch(err => {
        props.notify({ type: "error", title: "Eliminar usuario", text: "No se pudo eliminar al usuario" })
      })
  }

  return (
    <Main>
      <Row>
        <Col>
          <Card title="Usuarios de sistema" button={true} textButton="Nuevo usuario" onClick={() => setNuevo(true)}>
            <Row>
              <Col>
                <Table columns={columns} data={data} background="#FF8B03" exports={false} keyID="_id" paginationClass="table-buttons" pagination={false} />
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>

      <ModalSimple padding={20} open={remove} onClose={setRemove} cancel={true} textCancel="Cancelar" accept={true} textAccept="Aceptar" onClick={removeUser}>
        <Row>
          <Col>
            <h5>¿Esta seguro de eliminar al usuario {current.name}?</h5>
          </Col>
        </Row>
      </ModalSimple>

      <NewUser categories={props.store.categories} open={nuevo} onClosed={setNuevo} accept={true} cb={getUsers} />
    </Main>
  )
}

export default connect(SugerenciasServices);



const NewUser = connect((props) => {
  const [name, setName] = useState('');
  const [lastname, setLastname] = useState('');
  const [rut, setRut] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [role, setRole] = useState(null);
  const [err, setErr] = useState(false)
  const [errMsg, setErrMsg] = useState('')

  const [roles, setRoles] = useState([
    // { label: "SuperAdmin", value: "superadmin" },
    { label: "Administrador", value: "admin" },
    { label: "Full", value: "full" },
    { label: "Basico", value: "basic" }
  ])

  const newErr = (status, message = '') => {
    setErr(status);
    setErrMsg(message);
  }

  const close = () => {
    if (props.onClosed != undefined) props.onClosed(false);
  }

  const save = () => {

    if (role == null || role.length == 0 || role === '') {
      newErr(true, 'El campo rol es requerido');
      return false;
    } else newErr(false);

    if (rut == null || rut.length == 0 || rut === '') {
      newErr(true, 'El campo nombre es requerido');
      return false;
    } else newErr(false);

    if (name == null || name.length == 0 || name === '') {
      newErr(true, 'El campo nombre es requerido');
      return false;
    } else newErr(false);

    if (lastname == null || lastname.length == 0 || lastname === '') {
      newErr(true, 'El campo nombre es requerido');
      return false;
    } else newErr(false);



    if (email == null || email.length == 0 || email === '') {
      newErr(true, 'El campo nombre es requerido');
      return false;
    } else newErr(false);

    if (phone == null || phone.length == 0 || phone === '') {
      newErr(true, 'El campo nombre es requerido');
      return false;
    } else newErr(false);


    const pass = rut.split('-')[0];

    axios.post('/api/system/user', {
      name: name,
      lastname: lastname,
      rut: rut,
      email: email,
      phone: phone,
      password: pass,
      role: role.value,
    })
      .then(res => {
        props.notify({ type: "success", title: "Crear categoria de servicio", text: "Categoria creada" });
        if (props.onClosed != undefined) props.onClosed(false);
        if (props.cb != undefined) props.cb(false);
        setName('');
        setLastname('');
        setRut('');
        setEmail('');
        setPhone('');
        setRole(null);
        setErr(false)
        setErrMsg('')
      })
      .catch(err => {
        props.notify({ type: "error", title: "Crear categoria de servicio", text: "Error al crear la categoria" })
      })
  }

  return (
    <ModalSimple width={"400px"} padding={20} autoClose={false} open={props.open} onClose={close} cancel={props.cancel} textCancel={props.textCancel} accept={props.accept} textAccept={props.textAccept} onClick={save}>
      <Row>
        <Col>
          <h4 className="mb-4">Nuevo usuario</h4>
          <table className="table table-vertical-center">
            <tbody>
              <tr>
                <td><strong>ROL:</strong></td>
                <td>
                  <SelectLight disabled={true} options={roles} placeholder="Seleccionar un rol" onChange={v => setRole(v)} value={role} />
                </td>
              </tr>
              <tr>
                <td><strong>RUT:</strong></td>
                <td><input style={{ margin: 0, padding: 0 }} type="text" className="form-control" onChange={v => setRut(v.target.value)} /></td>
              </tr>
              <tr>
                <td><strong>Nombre:</strong></td>
                <td><input style={{ margin: 0, padding: 0 }} type="text" className="form-control" onChange={v => setName(v.target.value)} /></td>
              </tr>
              <tr>
                <td><strong>Apellido:</strong></td>
                <td><input style={{ margin: 0, padding: 0 }} type="text" className="form-control" onChange={v => setLastname(v.target.value)} /></td>
              </tr>
              <tr>
                <td><strong>Email:</strong></td>
                <td><input style={{ margin: 0, padding: 0 }} type="text" className="form-control" onChange={v => setEmail(v.target.value)} /></td>
              </tr>
              <tr>
                <td><strong>Teléfono:</strong></td>
                <td><input style={{ margin: 0, padding: 0 }} type="text" className="form-control" onChange={v => setPhone(v.target.value)} /></td>
              </tr>
            </tbody>
          </table>
        </Col>
        <Col className="text-center">{err ? <p style={{ color: "#ff4444" }}>{errMsg}</p> : null}</Col>
      </Row>
    </ModalSimple>
  )
})

NewUser.defaultProps = {
  open: false,
  cancel: true,
  accept: false,
  textCancel: "Cancelar",
  textAccept: 'Aceptar'
}




const TableEdit = connect((props) => {

  const [data, setData] = useState({})

  useEffect(() => {
    setData(props.current);
  }, [props.current])

  const setDataKey = (key, value) => {
    let current = Object.assign({}, data);
    current[key] = value;
    setData(current);

    if (props.onChange != undefined) props.onChange(current);
  }

  return (
    <table className="table table-vertical-center ">
      <tbody>
        <tr>
          <td><strong>RUT:</strong></td>
          <td><input style={{ margin: 0, padding: 0 }} type="text" className="form-control" value={data && data.name} onChange={v => setDataKey('name', v.target.value)} /></td>
        </tr>
        <tr>
          <td><strong>Nombre:</strong></td>
          <td><input style={{ margin: 0, padding: 0 }} type="text" className="form-control" value={data && data.name} onChange={v => setDataKey('name', v.target.value)} /></td>
        </tr>
        <tr>
          <td><strong>Apellido:</strong></td>
          <td><input style={{ margin: 0, padding: 0 }} type="text" className="form-control" value={data && data.name} onChange={v => setDataKey('name', v.target.value)} /></td>
        </tr>
        <tr>
          <td><strong>Email:</strong></td>
          <td><input style={{ margin: 0, padding: 0 }} type="text" className="form-control" value={data && data.name} onChange={v => setDataKey('name', v.target.value)} /></td>
        </tr>
        <tr>
          <td><strong>Teléfono:</strong></td>
          <td><input style={{ margin: 0, padding: 0 }} type="text" className="form-control" value={data && data.name} onChange={v => setDataKey('name', v.target.value)} /></td>
        </tr>
        <tr>
          <td><strong>Password:</strong></td>
          <td><input style={{ margin: 0, padding: 0 }} type="text" className="form-control" value={data && data.name} onChange={v => setDataKey('name', v.target.value)} /></td>
        </tr>
        <tr>
          <td><strong>ROL:</strong></td>
          <td><SelectLight disabled={true} options={props.store.categories} placeholder="Seleccionar una categoria" value={data.cat && { value: data.cat._id, label: data.cat.name }} onChange={v => setDataKey('cat', v)} /></td>
        </tr>
        <tr>
          <td className="pv-15"><strong>Codigo interno:</strong></td>
          <td className="pv-15">{data && data._id}</td>
        </tr>
      </tbody>
    </table>
  )
})