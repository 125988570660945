import React from 'react'
import './index.css'

const size = 14;

export const BtnSee = (props) => {
    const click = () => {
        if (props.onClick !== undefined) props.onClick();
    }
    return (
        <button type="button" className="btn btn-icon btn-see buttonsTable" title="Ver" onClick={() => click()}>
            <svg width={size} height={size} viewBox="0 0 384 427" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M341.334 42.667H252.054C243.307 17.92 219.84 0 192 0C164.16 0 140.693 17.92 131.947 42.667H42.667C19.094 42.667 0 61.76 0 85.334V384C0 407.573 19.093 426.667 42.667 426.667H341.334C364.907 426.667 384.001 407.574 384.001 384V85.333C384 61.76 364.907 42.667 341.334 42.667ZM192 42.667C203.733 42.667 213.333 52.16 213.333 64C213.333 75.84 203.733 85.333 192 85.333C180.267 85.333 170.667 75.84 170.667 64C170.667 52.16 180.267 42.667 192 42.667ZM234.667 341.333H85.334V298.666H234.667V341.333ZM298.667 256H85.334V213.333H298.667V256ZM298.667 170.667H85.334V128H298.667V170.667Z" fill="black" />
            </svg>
        </button>
    )
}

export const BtnDelete = (props) => {
    const click = () => {
        if (props.onClick !== undefined) props.onClick();
    }
    return (
        <button type="button" className="btn btn-icon btn-delete buttonsTable" title="Eliminar" onClick={() => click()}>
            <svg width={size} height={size} viewBox="0 0 299 384" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M21.333 341.333C21.333 364.8 40.533 384 64 384H234.667C258.133 384 277.333 364.8 277.333 341.333V85.333H21.333V341.333ZM73.92 189.44L104.107 159.253L149.333 204.48L194.56 159.253L224.747 189.44L179.52 234.667L224.747 279.894L194.56 310.081L149.333 264.853L104.106 310.08L73.919 279.893L119.146 234.666L73.92 189.44Z" fill="black" />
                <path d="M224 21.333L202.666 0H96L74.666 21.333H0V64H298.666V21.333H224Z" fill="black" />
            </svg>
        </button>
    )
}


export const BtnDownload = (props) => {
    const click = () => {
        if (props.onClick !== undefined) props.onClick();
    }
    return (
        <button type="button" className="btn btn-icon btn-download buttonsTable" title="Eliminar" onClick={() => click()}>
            <svg width={size} height={size} viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M10.8873 0.115502C10.7956 0.0189134 10.6698 -0.0390701 10.5395 -0.0390701H2.73753C1.29787 -0.0390701 0.109509 1.14456 0.109509 2.58407V17.3377C0.109509 18.7773 1.29787 19.961 2.73753 19.961H13.3268C14.7665 19.961 15.9549 18.7773 15.9549 17.3377V5.62271C15.9549 5.49713 15.8969 5.37644 15.8148 5.28458L10.8873 0.115502ZM11.0273 1.66626L14.3269 5.13001H12.182C11.5443 5.13001 11.0273 4.61792 11.0273 3.98025V1.66626ZM13.3268 18.9948H2.73753C1.83421 18.9948 1.0757 18.246 1.0757 17.3377V2.58407C1.0757 1.68075 1.82933 0.927118 2.73753 0.927118H10.0612V3.98025C10.0612 5.15412 11.0081 6.09619 12.182 6.09619H14.9887V17.3377C14.9887 18.246 14.2351 18.9948 13.3268 18.9948Z" fill="black" />
                <path d="M12.0081 15.6614H4.05639C3.79073 15.6614 3.5733 15.8787 3.5733 16.1445C3.5733 16.4102 3.79073 16.6276 4.05639 16.6276H12.0129C12.2786 16.6276 12.496 16.4102 12.496 16.1445C12.496 15.8787 12.2786 15.6614 12.0081 15.6614Z" fill="black" />
                <path d="M7.67953 13.9274C7.77139 14.024 7.89697 14.082 8.03216 14.082C8.16751 14.082 8.29309 14.024 8.38479 13.9274L11.2158 10.8888C11.3993 10.6954 11.3848 10.3863 11.1917 10.2076C10.9983 10.024 10.6892 10.0384 10.5105 10.2317L8.51525 12.3718V7.09648C8.51525 6.83067 8.29782 6.61338 8.03216 6.61338C7.7665 6.61338 7.54907 6.83067 7.54907 7.09648V12.3718L5.55871 10.2317C5.37514 10.0385 5.07073 10.024 4.87755 10.2076C4.68438 10.3912 4.66988 10.6956 4.85344 10.8888L7.67953 13.9274Z" fill="black" />
            </svg>

        </button>
    )
}

export const BtnEdit = (props) => {
    const click = () => {
        if (props.onClick !== undefined) props.onClick();
    }
    return (
        <button type="button" className="btn btn-icon btn-edit buttonsTable" title="Editar" onClick={() => click()}>
            <svg width={size} height={size} viewBox="0 0 342 427" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M213.333 0H42.666C19.2 0 0.213001 19.2 0.213001 42.667L0 384C0 407.467 18.987 426.667 42.453 426.667H298.666C322.133 426.667 341.333 407.467 341.333 384V128L213.333 0ZM170.666 341.333C126.933 341.333 89.493 314.986 72.959 277.333H109.439C122.986 296.64 145.279 309.333 170.559 309.333C211.839 309.333 245.226 275.946 245.226 234.666C245.226 193.386 211.839 159.999 170.559 159.999C141.652 159.999 116.906 176.532 104.532 200.639L138.665 234.666H53.333V149.333L81.066 177.066C100.053 147.627 133.013 128 170.666 128C229.546 128 277.333 175.787 277.333 234.667C277.333 293.547 229.546 341.333 170.666 341.333Z" fill="black" />
            </svg>

        </button>
    )
}

export const BtnModalAdd = (props) => {
    const click = () => {
        if (props.onClick !== undefined) props.onClick();
    }
    return (
        !props.floatCircle ? <button style={{ position: props.position, bottom: props.bottom, left: props.left }} type="button" className={"btn btn-secondary " + (props.className ? props.className : "")} data-toggle="modal" data-target={"#" + props.modal}>
            {props.children}
        </button> : <button data-toggle="modal" data-target={"#" + props.modal} type="button" className="btn btn-primary buttonsFloatCircle" title="Editar" onClick={() => click()}>
            <svg width={size} height={size} viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M6.98723 12.6007C7.20599 12.6007 7.39902 12.5107 7.547 12.3693C7.68856 12.2279 7.77863 12.0287 7.77863 11.8101V7.48465H12.1088C12.3276 7.48465 12.5206 7.39467 12.6686 7.25327C12.8102 7.11188 12.9003 6.91263 12.9003 6.69411C12.9003 6.25706 12.5464 5.90356 12.1153 5.90999H7.78507V1.5845C7.78507 1.14745 7.43119 0.793952 7.0001 0.800379C6.56257 0.800379 6.20869 1.15387 6.21512 1.5845V5.90999H1.88491C1.44739 5.90999 1.09351 6.26349 1.09994 6.69411C1.09994 7.13116 1.45382 7.48465 1.88491 7.47822H6.21512V11.8037C6.19582 12.2472 6.5497 12.6007 6.98723 12.6007Z" fill="white" stroke="white" />
            </svg>
        </button>
    )
}

BtnModalAdd.defaultProps = {
    floatCircle: false,
    position: 'relative',
    bottom: 0,
    left: 0
}

export const PrimaryBtn = (props) => {
    const click = () => {
        if (props.onClick !== undefined) props.onClick();
    }
    return (
        <button type="button" className={"btn btn-primary " + (props.className ? props.className : "")} onClick={() => click()}>
            {props.children}
        </button>
    )
}

export const SecondaryBtn = (props) => {
    const click = () => {
        if (props.onClick !== undefined) props.onClick();
    }
    return (
        <button style={{ position: props.position, bottom: props.bottom, left: props.left }} type="button" className={"btn btn-secondary " + (props.className ? props.className : "")} onClick={() => click()}>
            {props.children}
        </button>
    )
}

SecondaryBtn.defaultProps = {
    position: 'relative',
    bottom: 0,
    left: 0
}

export const WhiteBtn = (props) => {
    const click = () => {
        if (props.onClick !== undefined) props.onClick();
    }
    return (
        <button type="button" className={"btn btn-white " + (props.className ? props.className : "")} onClick={() => click()}>
            {props.children}
        </button>
    )
}

BtnModalAdd.defaultProps = {
    modal: 'exampleModal'
}
