import React from 'react'
import GoBack from '../goBack';
import './style.css'

const Header = (props) => {
    return (
        <div className="pt-4">
            {props.back ? <GoBack /> : null}<h2>{props.title}</h2>
        </div>
    )
}

Header.defaultProps = {
    title: '',
    back: false
}

export default Header;