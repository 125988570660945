import {
  PUSH_NOTIFIACTION,
  REMOVE_NOTIFIACTION,
  UPDATE_VERSION,
  TOGGLE_SIDEBAR,
  SET_HEAD,
  TOOLS,
  GET_PROJECTS,
  TOGGLE_SIDESHEETS,
  SET_VIEWPORT,
  SET_GOBACK,
  ACTIVE_MENU,
  SET_FORM_TYPE,
  USER_ID_ACTIVATE,
  CAT_SERVICES,
  GROUPS_SERVICES,
  GET_EXPERTS,
  GET_CLIENTS,
  MASTER_SERVICES
} from "./variables";

const defaultState = {
  name: "v1",
  sidebar: true,
  sidesheet: false,
  head: 'Dashboard',
  tools: null,
  projects: [],
  viewport: null,
  goback: false,
  active_menu: 0,
  notifications: [],
  form_type: 'login',
  user_id_activate: '',
  categories: [],
  experts: [],
  clients: [],
  groups: [],
  masterServices: []
};

export default function (state = defaultState, { type, payload }) {
  switch (type) {
    case UPDATE_VERSION:
      return Object.assign({}, state, { name: payload });
    case PUSH_NOTIFIACTION:
      if (payload['id'] == undefined) {
        payload['id'] = Math.random().toString(36).slice(-8);
        payload['control'] = "default";
      } else {
        payload['control'] = "custom";
      }
      state.notifications.push(payload);
      return Object.assign({}, state, { notifications: state.notifications });
    case REMOVE_NOTIFIACTION:
      state.notifications.splice(state.notifications.findIndex(e => e.id === payload.id), 1)
      return Object.assign({}, state, { notifications: state.notifications });
    case TOGGLE_SIDEBAR:
      return Object.assign({}, state, { sidebar: payload });
    case TOGGLE_SIDESHEETS:
      return Object.assign({}, state, { sidesheet: payload });
    case SET_HEAD:
      return Object.assign({}, state, { head: payload });
    case TOOLS:
      return Object.assign({}, state, { tools: payload });
    case SET_VIEWPORT:
      return Object.assign({}, state, { viewport: payload });
    case GET_PROJECTS:
      return Object.assign({}, state, { projects: payload });
    case SET_GOBACK:
      return Object.assign({}, state, { goback: payload });
    case ACTIVE_MENU:
      return Object.assign({}, state, { active_menu: payload });
    case SET_FORM_TYPE:
      return Object.assign({}, state, { form_type: payload });
    case USER_ID_ACTIVATE:
      return Object.assign({}, state, { user_id_activate: payload });
    case CAT_SERVICES:
      return Object.assign({}, state, { categories: payload });
    case GROUPS_SERVICES:
      return Object.assign({}, state, { groups: payload });
    case GET_EXPERTS:
      return Object.assign({}, state, { experts: payload });
    case GET_CLIENTS:
      return Object.assign({}, state, { clients: payload });
    case MASTER_SERVICES:
      return Object.assign({}, state, { masterServices: payload });
    default:
      return state;
  }
}
