import React, { useEffect } from 'react';
import Rutas from '../../routes/Routes';
// Componentes
import Navbar from '../../components/navbar/index.js';
import Sidebar from '../../components/sidebar/index.js';
import './style.css';
import { connect } from '../../store';
import Notifications from '../../components/notify';
import { getUserId } from '../../lib/Router'
import { getDataIO } from '../../sockets'
import { loadData } from '../../utils'

const Layout = (props) => {
    const userId = getUserId().userId;
    useEffect(() => {
        loadData('/api/catservice', props.setCategories, { value: '_id', label: 'name' })
        loadData('/api/groups/service', props.setGroups, { value: '_id', label: 'name' })

        getDataIO('notify_user_services', '/api/experts', props.setExperts)
        getDataIO('notify_cat_services', '/api/catservice', props.setCategories, { value: '_id', label: 'name' })
        getDataIO('notify_master_services', '/api/master/service', props.setMasterServices)
        getDataIO('notify_group_services', '/api/groups/service', props.setGroups, { value: '_id', label: 'name' })
        // getDataIO('notify_user_services','/api/clients',setClients)
    }, [])

    return (
        <div>
            <Navbar />
            <div className={`d-flex ${props.store.sidebar ? '' : 'toggled'}`} id="wrapper">
                <Sidebar />
                <div id="page-content-wrapper">
                    <Rutas />
                </div>
            </div>
            <Notifications />
        </div>
    )
}

export default connect(Layout);

