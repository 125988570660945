import React from "react";
import { useHistory } from "react-router-dom";
import { connect } from '../../store';
import './index.css';

const size = 16;

const GoBack = (props) => {
    const history = useHistory();

    return (
        <div className="goback">
            {props.store.goback ? <div>
                <button className="btn-menu" onClick={() => history.goBack()}>
                    <svg width={size} height={size} viewBox="0 0 512 512" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M208.594 256.47L465.605 0H275.912L63.8121 214C52.4701 225.348 46.2331 240.433 46.2491 256.476C46.2661 272.519 52.5331 287.592 63.8381 298.857L275.936 512H465.751L208.594 256.47ZM92.1321 270.583C88.3441 266.808 86.2551 261.784 86.2491 256.436C86.2441 251.088 88.3231 246.06 92.1631 242.218L292.586 40H368.893L151.905 256.53L368.747 472H292.562L92.1321 270.583Z" fill="black" />
                    </svg>
                    <span style={{ marginLeft: 5, fontWeight: 600 }}>Volver</span>
                </button>
            </div> : null}
        </div>
    )
}

export default connect(GoBack);