'use strict'

import React, { useState } from 'react'
import { ChromePicker,SketchPicker, GithubPicker, TwitterPicker } from 'react-color'
import './style.css'


const ColorPicker = (props) => {
    const [displayColorPicker, setDisplayColorPicker] = useState(false);


    const handleClick = () => {
        setDisplayColorPicker(!displayColorPicker)
    };

    const handleClose = () => {
        setDisplayColorPicker(false)
    };

    const popover = {
        position: 'absolute',
        zIndex: '2',
    }
    const cover = {
        position: 'fixed',
        top: '0px',
        right: '0px',
        bottom: '0px',
        left: '0px',
    }

    const changeColor = (hex) => {
        handleClose();
        if(props.onChange != undefined) props.onChange(hex)
    }

    return (
        <div>
            <button onClick={handleClick} style={{background:props.color, border:`none`, borderRadius:5, height:25, color:"#fff"}}>Seleccionar color</button>
            { displayColorPicker ? <div style={popover}>
                <div style={cover} onClick={handleClose} />
                <TwitterPicker color={props.color} onChange={v => changeColor(v.hex)}/>
            </div> : null}
        </div>
    )
}

ColorPicker.defaultProps = {
    color: '#222'
}

export default ColorPicker;